import { defineStore } from 'pinia'

const DB_NAME = 'ScannerCache'
const STORE_NAME = 'scanData'
const DB_VERSION = 1
const CACHE_EXPIRY_DAYS = 7 // 缓存过期时间（天）

// 检查缓存是否过期
function isCacheExpired(timestamp) {
  const now = Date.now()
  const expiryTime = CACHE_EXPIRY_DAYS * 24 * 60 * 60 * 1000 // 转换为毫秒
  return (now - timestamp) > expiryTime
}

// 处理数据使其可序列化
function makeSerializable(data) {
  // 处理 File 对象
  if (data instanceof File) {
    return {
      _type: 'File',
      name: data.name,
      type: data.type,
      size: data.size
    }
  }
  
  // 处理数组
  if (Array.isArray(data)) {
    return data.map(item => makeSerializable(item))
  }
  
  // 处理对象
  if (data && typeof data === 'object') {
    const result = {}
    for (const key in data) {
      // 跳过函数和特殊对象
      if (typeof data[key] === 'function') continue
      if (data[key] instanceof Event) continue
      if (data[key] instanceof Node) continue
      
      try {
        // 测试是否可以被克隆
        structuredClone(data[key])
        result[key] = makeSerializable(data[key])
      } catch (e) {
        console.warn(`Skipping non-serializable property: ${key}`)
      }
    }
    return result
  }
  
  return data
}

export const useScanStore = defineStore('scan', {
  state: () => ({
    // 内存中的缓存
    cachedData: new Map(),
    db: null
  }),

  actions: {
    // 初始化 IndexedDB
    async initDB() {
      if (this.db) return this.db

      return new Promise((resolve, reject) => {
        const request = indexedDB.open(DB_NAME, DB_VERSION)

        request.onerror = () => {
          console.error("Error opening DB:", request.error)
          reject(request.error)
        }

        request.onsuccess = (event) => {
          this.db = event.target.result
          resolve(this.db)
        }

        request.onupgradeneeded = (event) => {
          const db = event.target.result
          if (!db.objectStoreNames.contains(STORE_NAME)) {
            db.createObjectStore(STORE_NAME, { keyPath: 'id' })
          }
        }
      })
    },

    // 缓存数据
    async cacheData(exam_id, subject_id, data) {
      const key = `${exam_id}_${subject_id}`
      const timestamp = Date.now()
      
      try {
        // 处理数据使其可序列化
        const serializedData = makeSerializable(data)
        
        // 保存到内存
        this.cachedData.set(key, {
          ...serializedData,
          timestamp
        })

        await this.initDB()
        const transaction = this.db.transaction([STORE_NAME], 'readwrite')
        const store = transaction.objectStore(STORE_NAME)

        // 保存到 IndexedDB
        const cacheData = {
          id: key,
          data: {
            ...serializedData,
            timestamp
          }
        }

        return new Promise((resolve, reject) => {
          const request = store.put(cacheData)
          
          request.onsuccess = () => resolve()
          request.onerror = () => {
            console.error("Error saving to IndexedDB:", request.error)
            reject(request.error)
          }
        })
      } catch (error) {
        console.error('Error caching data:', error)
        throw error
      }
    },

    // 获取缓存数据
    async getCachedData(exam_id, subject_id) {
      const key = `${exam_id}_${subject_id}`
      
      // 检查内存缓存
      const memoryCache = this.cachedData.get(key)
      if (memoryCache) {
        // 检查是否过期
        if (isCacheExpired(memoryCache.timestamp)) {
          // 如果过期，清除缓存并返回 null
          await this.clearCache(exam_id, subject_id)
          return null
        }
        return memoryCache
      }

      try {
        await this.initDB()
        const transaction = this.db.transaction([STORE_NAME], 'readonly')
        const store = transaction.objectStore(STORE_NAME)

        return new Promise((resolve, reject) => {
          const request = store.get(key)
          
          request.onsuccess = async () => {
            if (request.result) {
              const cachedData = request.result.data
              
              // 检查是否过期
              if (isCacheExpired(cachedData.timestamp)) {
                // 如果过期，清除缓存并返回 null
                await this.clearCache(exam_id, subject_id)
                resolve(null)
                return
              }

              // 更新内存缓存
              this.cachedData.set(key, cachedData)
              resolve(cachedData)
            } else {
              resolve(null)
            }
          }
          
          request.onerror = () => {
            console.error("Error reading from IndexedDB:", request.error)
            reject(request.error)
          }
        })
      } catch (error) {
        console.error('Error getting cached data:', error)
        return null
      }
    },

    // 清除指定考试科目的缓存
    async clearCache(exam_id, subject_id) {
      const key = `${exam_id}_${subject_id}`
      this.cachedData.delete(key)

      try {
        await this.initDB()
        const transaction = this.db.transaction([STORE_NAME], 'readwrite')
        const store = transaction.objectStore(STORE_NAME)

        return new Promise((resolve, reject) => {
          const request = store.delete(key)
          
          request.onsuccess = () => resolve()
          request.onerror = () => {
            console.error("Error deleting from IndexedDB:", request.error)
            reject(request.error)
          }
        })
      } catch (error) {
        console.error('Error clearing cache:', error)
      }
    },

    // 清除所有缓存
    async clearAllCache() {
      this.cachedData.clear()

      try {
        await this.initDB()
        const transaction = this.db.transaction([STORE_NAME], 'readwrite')
        const store = transaction.objectStore(STORE_NAME)

        return new Promise((resolve, reject) => {
          const request = store.clear()
          
          request.onsuccess = () => resolve()
          request.onerror = () => {
            console.error("Error clearing IndexedDB:", request.error)
            reject(request.error)
          }
        })
      } catch (error) {
        console.error('Error clearing all cache:', error)
      }
    },

    // 清理所有过期缓存
    async clearExpiredCaches() {
      try {
        await this.initDB()
        const transaction = this.db.transaction([STORE_NAME], 'readwrite')
        const store = transaction.objectStore(STORE_NAME)

        return new Promise((resolve, reject) => {
          const request = store.openCursor()
          
          request.onsuccess = async (event) => {
            const cursor = event.target.result
            if (cursor) {
              const data = cursor.value.data
              if (isCacheExpired(data.timestamp)) {
                // 删除过期的缓存
                cursor.delete()
                // 同时从内存中删除
                this.cachedData.delete(cursor.key)
              }
              cursor.continue()
            } else {
              resolve()
            }
          }
          
          request.onerror = () => {
            console.error("Error clearing expired caches:", request.error)
            reject(request.error)
          }
        })
      } catch (error) {
        console.error('Error clearing expired caches:', error)
      }
    }
  }
})

// 导出一个不需要在setup中使用的版本
export function useScanStoreWithOut() {
  return useScanStore()
} 