import { defineStore } from 'pinia'
import { store } from '../index'


// 持久化部分
export const useAppStore = defineStore('app', {
    state: () => {
        return {
            exam_id: null,
            exam_name: "",
            exam_type: "",
            subject_id:null,
            subject_name:"",
            exam_subject_ids:[],
            exam_subjects_name:[],
            exam_unit_id: null,
            exam_unit_name: "",
            exam_grades: [], // 考试年级

            exam_all_unit_ids: null, // 学校单位id
            exam_all_unit_names: [], // 学校单位名称

            // 下面是持久化的数据
            persist_exam_id: null,
            persist_subject_id: null,
            persist_exam_name: "",
            persist_subject_name: "",
            persist_exam_type: "",
            persist_exam_unit_id: null,
            persist_exam_unit_name: "",
            persist_exam_grades: [],
            persist_exam_all_unit_ids: null,
            persist_exam_all_unit_names: [],
            persist_exam_subject_ids: [],
            persist_exam_subjects_name: [],
            markingConfig:{} // 阅卷的配置 question_id -> {zoom: 1,scrollLeft: 0,scrollRight: 0}
        }
    },
    getters: {},
    actions: {
        setExamAllUnit(unit_ids,unit_names){
            this.persist_exam_all_unit_ids = unit_ids
            this.persist_exam_all_unit_names = unit_names
            this.exam_all_unit_ids = unit_ids
            this.exam_all_unit_names = unit_names
        },
        setExam(exam_id, exam_name) {
            this.exam_id = exam_id
            this.persist_exam_id = exam_id
            this.exam_name = exam_name
            this.persist_exam_name = exam_name
        },
        setSubject(subject_id,subject_name) {
            this.subject_id = subject_id
            this.persist_subject_id = subject_id
            this.subject_name = subject_name
            this.persist_subject_name = subject_name
        },
        setExamSubject(exam_subject_ids,exam_subjects_name) {
            this.exam_subject_ids = exam_subject_ids
            this.persist_exam_subject_ids = exam_subject_ids
            this.exam_subjects_name = exam_subjects_name
            this.persist_exam_subjects_name = exam_subjects_name
        },
        setExamUnit(exam_unit_id,exam_unit_name) {
            this.exam_unit_id = exam_unit_id
            this.exam_unit_name = exam_unit_name
            this.persist_exam_unit_id = exam_unit_id
            this.persist_exam_unit_name = exam_unit_name
        },
        setGrades(grades){
            this.exam_grades = grades
            this.persist_exam_grades = grades
        },
        setExamType(exam_type){
            this.exam_type = exam_type
            this.persist_exam_type = exam_type
        },
        setMarkingConfigByQuestion(qid,config){
            this.markingConfig[qid] = config
        },
        getMarkingConfigByQuestion(qid){
            return this.markingConfig[qid]
        },
        removeMarkingConfigByQuestion(qid){
            delete this.markingConfig[qid]
        }
    },
    persist: {
        paths: ['markingConfig', 'persist_exam_id', 'persist_subject_id', 'persist_exam_name', 'persist_subject_name', 
            'persist_exam_type', 'persist_exam_unit_id', 'persist_exam_unit_name', 'persist_exam_grades',
            'persist_exam_all_unit_ids','persist_exam_all_unit_names','persist_exam_subject_ids','persist_exam_subjects_name'],  // 只持久化 markingConfig, persist_exam_id, persist_subject_id
    }
})

export const useAppStoreWithOut = () => {
    const appStore = useAppStore(store)
    // 取出persist_xxx 赋值给appStore
    const persist_exam_id = appStore.persist_exam_id
    const persist_subject_id = appStore.persist_subject_id
    const persist_exam_name = appStore.persist_exam_name
    const persist_subject_name = appStore.persist_subject_name
    const persist_exam_type = appStore.persist_exam_type
    const persist_exam_unit_id = appStore.persist_exam_unit_id
    const persist_exam_unit_name = appStore.persist_exam_unit_name
    const persist_exam_grades = appStore.persist_exam_grades
    const persist_exam_all_unit_ids = appStore.persist_exam_all_unit_ids
    const persist_exam_all_unit_names = appStore.persist_exam_all_unit_names
    const persist_exam_subject_ids = appStore.persist_exam_subject_ids
    const persist_exam_subjects_name = appStore.persist_exam_subjects_name
    if (persist_exam_all_unit_ids){
        appStore.setExamAllUnit(persist_exam_all_unit_ids, persist_exam_all_unit_names)
    }
    if (persist_exam_id){
        appStore.setExam(persist_exam_id, persist_exam_name)
    }
    if (persist_subject_id){
        appStore.setSubject(persist_subject_id, persist_subject_name)
    }
    if (persist_exam_type){
        appStore.setExamType(persist_exam_type)
    }
    if (persist_exam_unit_id){
        appStore.setExamUnit(persist_exam_unit_id, persist_exam_unit_name)
    }
    if (persist_exam_grades){
        appStore.setGrades(persist_exam_grades)
    }
    if (persist_exam_subject_ids){
        appStore.setExamSubject(persist_exam_subject_ids, persist_exam_subjects_name)
    }
    return appStore
}
