<script>
import { useAppStoreWithOut } from "@/store/modules/app";
import { ArrowUp, ArrowDown, ArrowLeft } from '@element-plus/icons-vue'  // 确保正确导入
import ExamAreaManage from "./ExamAreaManage.vue";
import ExamTimeManage from "./ExamTimeManage.vue";
import { Zip } from "@icon-park/vue-next";

export default {
  name: "ExamRoomDesign",
  components: {
    ExamAreaManage,
    ExamTimeManage,
    ArrowUp,
    ArrowDown,
    ArrowLeft
  },
  setup() {
    const appStore = useAppStoreWithOut()
    return { appStore }
  },
  data() {
    return {
      grades: [],
      units: [],
      subjects: [],
      exam: {},
      grade: null,
      unit: null,
      examAreas: [],
      examRoomData: [],
      stepActive: 0,

      checkedArea: [],
      designParams: {
        arrangement_mode: '',
        arrangement_order: '',
        arrangement_score_exam: [],
        arrangement_subjects: [],
      },
      selectedInfo: [],

      stuUnit: null,
      studentSeat: [],
      treeData: [],
      treeProps: {
        value: 'id',
        label: 'name',
        children: 'students'
      },
      checkedStudents: [],
      editExamAreaDialog: false,
      editExamAreaData: null,

      addRoomDialog: false,
      addRoomForm: {
        room_name_prefix: '',
        room_name_prefix_number: '',
        room_capacity: 30,
        room_name_suffix_number: '',
        room_name_suffix: '',
        room_nick_name_suffix: '',
        room_nick_name_prefix: '',
      },

      selectedDataDialog: false,
      areaManageDialog: false,
      expandedItem: null,
      expandedGrade: null,
      expandedUnit: null,
      isMouseDown: false,
      lastSelectedSeat: null,

      // 添加筛选条件
      studentFilter: {
        class: '',
        name: '',
        number: ''
      },
      seatFilter: {
        area: '',
        room: '',
        seatNum: ''
      },
      arrangeLoading: false, // 编排加载状态

      examSubjects: [],
      activeTab: '', // 当前激活的标签页
      tabLoading: false, // 添加标签页加载状态
      searchExamList: [],

      designData: [],
      arrangementData: [],
      showDownLoadDialog: false,
      showViewDialog: false,

      arrangementSearchParams: {
        exam_id: this.appStore.exam_id,
        design_id: '',
        student_name: '',
        student_number: '',
        class_name: '',
        school_name: '',
        grade_name: '',
        area_name: '',
        room_name: '',
        seat_num: '',
        subject_name: '',
        page: 1,
        page_size: 15,
        total: 0,
      },
      downloadData: {
        checkList: [],
        exam_id: null,
        design_id: null,
      },
      showDownLoading: false,
      unitHistoryExam: [],
    }
  },
  watch: {
    stepActive(newVal, oldVal) {
      if (newVal === 1 && oldVal === 0) {
        // 从步骤0进入步骤1时重新加数据
        this.resetAndReloadData();
      }
    },
    selectedDataDialog(val) {
      if (val && this.selectedInfo.length > 0) {
        // 打开对话框时初始化数据
        this.initDialogData()
      }
    },
    // 修改标签页切换的处理
    activeTab: {
      handler(newTab, oldTab) {
        if (!newTab || newTab === oldTab) return

        this.tabLoading = true
        // 重置过滤条件
        this.studentFilter = {
          class: '',
          name: '',
          number: ''
        }
        this.seatFilter = {
          area: '',
          room: '',
          seatNum: ''
        }

        this.$nextTick(() => {
          setTimeout(() => {
            this.tabLoading = false
          }, 200)
        })
      }
    }
  },
  mounted() {
    this.getExamInfo()
    document.addEventListener('mouseup', this.handleMouseUp)
  },
  beforeUnmount() {
    document.removeEventListener('mouseup', this.handleMouseUp)
  },
  computed: {
    filteredStudents() {
      const students = this.currentTabData.students
      if (!students?.length) return []

      const { class: className, name, number } = this.studentFilter
      if (!className && !name && !number) return students

      return students.filter(student => {
        if (className && student.class_name !== className) return false
        if (name && !student.student_name.toLowerCase().includes(name.toLowerCase())) return false
        if (number && !student.student_number.includes(number)) return false
        return true
      })
    },

    filteredSeats() {
      const seats = this.currentTabData.seats
      if (!seats?.length) return []

      const { area, room, seatNum } = this.seatFilter
      if (!area && !room && !seatNum) return seats

      return seats.filter(seat => {
        if (area && seat.area_name !== area) return false
        if (room && seat.room_name !== room) return false
        if (seatNum && !seat.seat_num.toString().includes(seatNum)) return false
        return true
      })
    },

    currentClassOptions() {
      const students = this.currentTabData.students
      if (!students?.length) return []
      return [...new Set(students.map(s => s.class_name))]
    },

    currentAreaOptions() {
      const seats = this.currentTabData.seats
      if (!seats?.length) return []
      return [...new Set(seats.map(s => s.area_name))]
    },

    currentRoomOptions() {
      const seats = this.currentTabData.seats
      if (!seats?.length) return []
      return [...new Set(seats.map(s => s.room_name))]
    },

    canStartArrangement() {
      return this.selectedInfo.length > 0 &&
        this.selectedInfo.every(item => item.students?.length > 0 && item.seats?.length > 0 && item.students.length <= item.seats.length)
    },

    // 获取已选中的考场列表
    selectedRooms() {
      // 从选中的座位中提取考场信息
      const roomMap = new Map();
      const [grade_id, unit_id] = this.activeTab.split('_')
      const seatsInfo = this.selectedInfo.find(item => item.grade_id === Number(grade_id) && item.unit_id === Number(unit_id)).seats

      seatsInfo.forEach(seat => {
        const key = `${seat.room_id}`;
        if (!roomMap.has(key)) {
          const room = this.examRoomData.find(r => r.room_id === seat.room_id);
          if (room) {
            roomMap.set(key, {
              ...room,
              area_name: seat.area_name
            });
          }
        }
      });
      return Array.from(roomMap.values());
    },

    allStudentNumber() {
      let number = 0;
      this.selectedInfo?.forEach(item => {
        number += item.students?.length
      })
      return number
    },
    allSeatNumber() {
      let number = 0;
      this.selectedInfo?.forEach(item => {
        number += item.seats?.length
      })
      return number
    },
    // 获取当前标签页的数据
    currentTabData() {
      if (!this.activeTab) return { students: [], seats: [] }
      const [gradeId, unitId] = this.activeTab.split('_')
      return this.selectedInfo.find(item =>
        item.grade_id === Number(gradeId) &&
        item.unit_id === Number(unitId)
      ) || { students: [], seats: [] }
    },
    unitGrade() {
      const { units, grades } = this
      const mixData = []
      units.forEach(unit => {
        grades.forEach(grade => {
          mixData.push({
            unit_id: unit.id,
            grade_id: grade.id,
            unit_name: unit.name,
            grade_name: grade.name,
          })
        })
      })
      return mixData
    },
  },
  created() {

  },
  methods: {
    getExamInfo() {
      this.axios.post("/api/examroomdesign/getexaminfo", { exam_id: this.appStore.exam_id }).then(
        res => {
          this.exam = res.data.result
          const unit_ids = this.exam.unit_ids
          const unit_names = this.exam.unit_names
          this.units = unit_ids.map((id, index) => {
            return {
              id: id,
              name: unit_names[index],
            };
          });
          const grade_ids = this.exam.grade_ids
          const grade_names = this.exam.grades
          this.grades = grade_ids.map((grade_id, index) => {
            return {
              id: grade_id,
              name: grade_names[index],
            }
          })
          const subject_ids = this.exam.exam_subjects
          const subject_names = this.exam.exam_subject_name
          this.subjects = subject_ids.map((subject_id, index) => {
            return {
              id: subject_id,
              name: subject_names[index],
            }
          })
        }
      )
    },

    disabledSubject(subject) {
      const { designParams, examSubjects } = this;
      const subujectIds = designParams.arrangement_subjects.filter(id => id !== subject.subject_id);
      const selectedSubjects = examSubjects.filter(s => subujectIds.includes(s.subject_id));
      if (selectedSubjects.length === 0) {
        return false || !subject.isReady
      }
      const subjectStartTime = new Date(Date.parse(subject.exam_start_time));
      const subjectEndTime = new Date(Date.parse(subject.exam_end_time));
      const isConflict = selectedSubjects.some(s => {
        const sStartTime = new Date(Date.parse(s.exam_start_time));
        const sEndTime = new Date(Date.parse(s.exam_end_time));
        return (subjectStartTime >= sStartTime && subjectStartTime < sEndTime) ||
          (subjectEndTime > sStartTime && subjectEndTime <= sEndTime);
      });
      return isConflict || !subject.isReady
    },

    selecGradetUnit(grade, unit) {
      this.grade = grade
      this.unit = unit
      this.stuUnit = unit.id
      this.expandedGrade = grade
      this.expandedUnit = unit
      const str = grade.id + "_" + unit.id

      if (this.expandedItem === str) {
        this.expandedItem = null
        this.expandedGrade = null
        this.expandedUnit = null
        this.examAreas = []
        this.examRoomData = []
      } else {
        this.expandedItem = str

        // 先获取数据
        Promise.all([
          this.getStudentSeatInfo(),
          this.getExamAreaInfo()
        ]).then(() => {
          // 数据加载完成后，同步已选状态
          this.syncSelectedData()
        })
      }
    },

    // 添加同步已选数据的方法
    syncSelectedData() {
      // 获取当前年级和学校的已选数据
      const currentData = this.selectedInfo.find(item =>
        item.grade_id === this.grade.id &&
        item.unit_id === this.unit.id
      )

      if (!currentData) return

      // 同步学生选中状态
      if (this.$refs.tree?.[0] && currentData.students?.length) {
        const studentIds = currentData.students.map(s =>
          s.student_number + '-student'
        )
        this.$refs.tree[0].setCheckedKeys(studentIds)
      }

      // 同步座位选中状态
      if (currentData.seats?.length) {
        currentData.seats.forEach(selectedSeat => {
          const room = this.examRoomData.find(r => r.room_id === selectedSeat.room_id)
          if (room) {
            const seat = room.seats.find(s => s.seat_num === selectedSeat.seat_num)
            if (seat) {
              seat.isEmpty = false
              seat.isSelected = true
            }
          }
        })
      }
    },

    // 获取学生信息的方法，返回 Promise
    getStudentSeatInfo() {
      if (!this.designParams.arrangement_subjects || !this.stuUnit) {
        return Promise.resolve()
      }

      return this.axios.post("/api/examroomdesign/getstudenseatinfo", {
        exam_id: this.appStore.exam_id,
        unit_id: this.stuUnit,
        subject_ids: this.designParams.arrangement_subjects,
      }).then(res => {
        const data = res.data.result
        this.studentSeat = data
        this.treeData = []
        this.checkedStudents = []

        data.forEach((item) => {
          const students = item.students
          students.forEach(student => {
            student["id"] = student["student_number"] + "-student"
            student["name"] = student["student_name"]
            if (student["isDesigned"]) {
              this.checkedStudents.push(student["id"])
            }
          })
          const data = {
            id: item.class_id + "-class",
            name: item.class_name,
            students: students
          }
          this.treeData.push(data)
        })
      })
    },

    // 获取考区信息的方法，返回 Promise
    getExamAreaInfo() {
      return this.axios.post("/api/examroomdesign/getexamareainfo", {
        unit_id: this.unit.id
      }).then(res => {
        if (res.data.success) {
          this.examAreas = res.data.result
          const area_ids = this.examAreas.map(area => area.area_id)
          if (area_ids.length > 0) {
            return this.getRoomInfo(area_ids)
          }
        }
      })
    },

    // 获取考场信息的方法，返回 Promise
    getRoomInfo(area_ids) {
      return this.axios.post("/api/examroomdesign/getexamroomstate", {
        area_ids: area_ids,
        exam_id: this.appStore.exam_id,
        subject_ids: this.designParams.arrangement_subjects
      }).then(res => {
        if (res.data.success) {
          this.examRoomData = res.data.result
        }
      })
    },

    isExpandedItem(expandedItem, grade, unit) {
      if (expandedItem === null) {
        return false
      }
      if (unit === null) {
        if (typeof expandedItem !== 'string') {
          return false
        }
        let underscoreIndex = expandedItem.indexOf("_");
        if (underscoreIndex === -1) {
          return false
        }
        let result = expandedItem.slice(0, underscoreIndex);
        return grade.id.toString() === result
      } else {
        return expandedItem === grade.id + "_" + unit.id
      }
    },
    checkedUnDesigned() {
      const { grade, unit, treeData } = this;
      const checkedStudents = [];
      const params = [];
      treeData.forEach(classData => {
        classData.students
          .filter(student => !student.isFull)
          .forEach(student => {
            checkedStudents.push(student.id);
            const undesignedSeats = student.seats.filter(seat => !seat.isDesigned);
            params.push({
              student_name: student.student_name,
              student_number: student.student_number,
              class_name: classData.name,
              subjects: undesignedSeats.map(seat => seat.subject_id)
            });
          });
      });
      if (this.$refs.tree?.[0]) {
        this.$refs.tree[0].setCheckedKeys(checkedStudents);
      }
      const newInfo = {
        grade_id: grade.id,
        unit_id: unit.id,
        students: params,
        seats: []
      };
      const index = this.selectedInfo.findIndex(
        item => item.grade_id === grade.id && item.unit_id === unit.id
      );
      if (index === -1) {
        this.selectedInfo.push(newInfo);
      } else {
        this.selectedInfo[index].students = params
      }
    },

    selectSeat(room, seat) {
      // 提前返回无效的座位选择
      if (!seat.isEmpty && !seat.isSelected) {
        return
      }

      const { unit, grade } = this

      // 获取当前选择的索引
      const currentSelectionIndex = this.selectedInfo.findIndex(
        item => item.grade_id === grade.id && item.unit_id === unit.id
      )
      // 处取消选座
      if (seat.isSelected && currentSelectionIndex !== -1) {
        const currentSelection = this.selectedInfo[currentSelectionIndex]
        const seatIndex = currentSelection.seats.findIndex(s =>
          s.room_id === room.room_id && s.seat_num === seat.seat_num
        )
        if (seatIndex > -1) {
          currentSelection.seats.splice(seatIndex, 1)
          seat.isEmpty = true
          seat.isSelected = false
        }
        return
      }
      // 处理选择新座位
      if (seat.isEmpty) {
        const seatData = {
          room_id: room.room_id,
          room_name: room.room_name,
          seat_num: seat.seat_num,
          area_id: room.area_id,
          area_name: this.examAreas.find(area => area.area_id === room.area_id)?.area_name || ''
        }

        if (currentSelectionIndex !== -1) {
          this.selectedInfo[currentSelectionIndex].seats.push(seatData)
        } else {
          this.selectedInfo.push({
            unit_id: unit.id,
            grade_id: grade.id,
            students: [],
            seats: [seatData]
          })
        }

        seat.isEmpty = false
        seat.isSelected = true
      }
    },
    showSelectedData() {
      this.selectedInfo = this.selectedInfo.filter(item => item.students?.length > 0 || item.seats?.length > 0)
      this.selectedDataDialog = true
    },
    removeSelectedSeat(seat, tabKey) {
      if (!tabKey) return
      const [gradeId, unitId] = tabKey.split('_')
      const data = this.selectedInfo.find(item =>
        item.grade_id === Number(gradeId) &&
        item.unit_id === Number(unitId)
      )

      if (!data) return

      const index = data.seats.findIndex(s =>
        s.room_id === seat.room_id && s.seat_num === seat.seat_num
      )

      if (index > -1) {
        // 使用 Vue.set 进行数组更新
        data.seats = [
          ...data.seats.slice(0, index),
          ...data.seats.slice(index + 1)
        ]

        // 批量更新���场座位状态
        this.$nextTick(() => {
          const room = this.examRoomData.find(r => r.room_id === seat.room_id)
          if (room) {
            const targetSeat = room.seats.find(s => s.seat_num === seat.seat_num)
            if (targetSeat) {
              Object.assign(targetSeat, {
                isEmpty: true,
                isSelected: false
              })
            }
          }
        })
      }
    },
    showAreaManage() {
      this.areaManageDialog = true
    },
    toExportDataPage() {
      this.stepActive = 3
      this.getDesignData()
    },
    getDesignData() {
      this.axios.post("/api/examroomdesign/getdesigninfo", {
        exam_id: this.appStore.exam_id
      }).then(res => {
        this.designData = res.data.result
      })
    },
    handleMouseDown(event, room, seat) {
      if (event.button === 0) {
        this.isMouseDown = true
        if (!seat.isEmpty && !seat.isSelected) {
          return
        }
        this.selectSeat(room, seat)
      }
      this.lastSelectedSeat = seat
    },
    handleMouseMove(event, room) {
      if (!this.isMouseDown) return;

      // 获取鼠标相对网格的位置
      const grid = event.currentTarget.querySelector('.seats-grid');
      const rect = grid.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      // 计算座位大小（包括间隔）
      const seatWidth = 80; // 座位宽度
      const gap = 15; // 座位间隔
      const totalWidth = seatWidth + gap;

      // 计算鼠标在第几行第几列（添加边界检查）
      const col = Math.floor(x / totalWidth);
      const row = Math.floor(y / totalWidth);

      // 检查是否在有效的列范围内（0-14，因为是15列）
      if (col < 0 || col > 14) {
        return;
      }

      // 计算座位索引
      const index = row * 15 + col;

      // 如果索引有效且对应的座位存在
      if (index >= 0 && index < room.seats.length) {
        const seat = room.seats[index];
        // 确保不重复选择同一个座位且座位可选
        if (seat && seat !== this.lastSelectedSeat && (seat.isEmpty || seat.isSelected)) {
          this.selectSeat(room, seat);
          this.lastSelectedSeat = seat;
        }
      }
    },
    handleMouseUp(event) {
      if (event.button === 0) {
        this.isMouseDown = false
      }
      this.lastSelectedSeat = null
    },
    // 选择考区内所有空闲座位
    selectAllEmptySeatsInArea(area) {
      const rooms = this.examRoomData.filter(room => room.area_id === area.area_id)
      rooms.forEach(room => {
        this.selectAllEmptySeatsInRoom(room)
      })
    },
    // 取消考区内所有已选座位
    cancelAllSelectedSeatsInArea(area) {
      const rooms = this.examRoomData.filter(room => room.area_id === area.area_id)
      rooms.forEach(room => {
        this.cancelAllSelectedSeatsInRoom(room)
      })
    },
    // 选择考场内所有空闲座位
    selectAllEmptySeatsInRoom(room) {
      room.seats.forEach(seat => {
        if (seat.isEmpty && !seat.isSelected) {
          this.selectSeat(room, seat)
        }
      })
    },
    // 取消考场内所已选座位
    cancelAllSelectedSeatsInRoom(room) {
      room.seats.forEach(seat => {
        if (seat.isSelected) {
          this.selectSeat(room, seat)
        }
      })
    },
    // 获取考区已选座位数
    getAreaSelectedCount(area) {
      const rooms = this.examRoomData.filter(room => room.area_id === area.area_id)
      let count = 0
      rooms.forEach(room => {
        count += room.seats.filter(seat => seat.isSelected).length
      })
      return count || 0
    },
    // 获取考场已选座位数
    getRoomSelectedCount(room) {
      return room.seats.filter(seat => seat.isSelected).length || 0
    },

    handleStudentCheck(data, checked) {
      const { grade, unit } = this;

      const checkdeKeys = checked.checkedKeys
      const clickedKey = data.id
      // 判断当前是进行选中还是进行取消选中
      const isCheck = checkdeKeys.includes(clickedKey)

      const currentSelectionIndex = this.selectedInfo.findIndex(item => item.grade_id === grade.id && item.unit_id === unit.id);
      if (currentSelectionIndex === -1) {
        this.selectedInfo.push({
          grade_id: grade.id,
          unit_id: unit.id,
          students: [],
          seats: []
        })
      }

      // 如果是班级节点
      if (!data.student_number) {
        // 获取该班级下的所有学生
        const students = data.students || [];
        students.forEach(student => {
          // 如果进行选中
          if (isCheck) {
            // 获取未设计的座位
            const undesignedSeats = student.seats.filter(seat => !seat.isDesigned);
            this.selectedInfo.find(item => item.grade_id === grade.id && item.unit_id === unit.id).students.push({
              student_name: student.student_name,
              student_number: student.student_number,
              class_name: data.name,
              subject_ids: undesignedSeats.map(seat => seat.subject_id)
            })
          } else {
            // 如果进行取消选中
            const index = this.selectedInfo[currentSelectionIndex].students.findIndex(
              s => s.student_number === student.student_number
            );
            if (index > -1) {
              this.selectedInfo[currentSelectionIndex].students.splice(index, 1);
            }
          }
        });
      } else {
        // 如果是学生节点
        const student = this.treeData
          .flatMap(classData => classData.students)
          .find(s => s.id === data.id);

        if (student) {
          const undesignedSeats = student.seats.filter(seat => !seat.isDesigned);

          // 如果进行选中
          if (isCheck) {
            // 添加到选中列表
            this.selectedInfo.find(item => item.grade_id === grade.id && item.unit_id === unit.id).students.push({
              student_name: student.student_name,
              student_number: student.student_number,
              class_name: data.name,
              subject_ids: undesignedSeats.map(seat => seat.subject_id)
            })
          } else {
            // 从选中列表移除
            const index = this.selectedInfo[currentSelectionIndex].students.findIndex(
              s => s.student_number === student.student_number
            );
            if (index > -1) {
              this.selectedInfo[currentSelectionIndex].students.splice(index, 1);
            }
          }
        }
      }

    },
    removeSelectedStudent(student, tabKey) {
      if (!tabKey) return
      const [gradeId, unitId] = tabKey.split('_')
      const data = this.selectedInfo.find(item =>
        item.grade_id === Number(gradeId) &&
        item.unit_id === Number(unitId)
      )

      if (!data) return

      const index = data.students.findIndex(
        s => s.student_number === student.student_number
      )

      if (index > -1) {
        // 使用 Vue.set 进行数组更新
        data.students = [
          ...data.students.slice(0, index),
          ...data.students.slice(index + 1)
        ]

        // 更新树的选中状态
        if (this.$refs.tree?.[0]) {
          const studentId = student.student_number + '-student'
          const checkedKeys = this.$refs.tree[0].getCheckedKeys()
          const studentNode = this.$refs.tree[0].getCheckedNodes(studentId)
          // 过滤掉要移除的学生节点
          const newCheckedKeys = checkedKeys.filter(key => key !== studentId && !key.endsWith('-class'))
          this.$refs.tree[0].setCheckedKeys(newCheckedKeys)
        }
      }
    },

    toCheckDataPage() {
      const { designParams } = this
      let errMessage = ''
      if (designParams.arrangement_mode === '') {
        errMessage += '请选择考场模式 '
      }
      if (designParams.arrangement_subjects.length === 0) {
        errMessage += '请选择编排科目 '
      }
      if (designParams.arrangement_order === '') {
        errMessage += '请选择排序方式 '
      }
      if (designParams.arrangement_order === 'score') {
        if (designParams.arrangement_score_exam.length === 0) {
          errMessage += '当排序方式为成绩时，请选择成绩作为排序依据的考试 '
        }
      }
      if (errMessage) {
        this.$message.warning(errMessage)
        return
      }

      this.stepActive = 1;
    },

    // 取消所有选中学生的方法
    cancelAllCheckedStudents() {
      const { grade, unit } = this;
      if (this.$refs.tree && this.$refs.tree[0]) {
        this.$refs.tree[0].setCheckedKeys([]);
      }
      const index = this.selectedInfo.findIndex(
        item => item.grade_id === grade.id && item.unit_id === unit.id
      );
      if (index !== -1) {
        this.selectedInfo[index].students = [];
      }
    },
    startArrangement() {
      this.selectedDataDialog = false;
      this.activeTab = `${this.selectedInfo[0].grade_id}_${this.selectedInfo[0].unit_id}`
      this.stepActive = 2; // 进入编排步骤
    },

    // 执行编排
    executeArrangement() {
      this.arrangeLoading = true;
      this.axios.post("/api/examroomdesign/arrange", {
        exam_id: this.appStore.exam_id,
        arrangement_data: this.selectedInfo,
        arrangement_params: this.designParams
      }).then(res => {
        if (res.data.success) {
          this.$message.success('编排成功！');
          this.getDesignData()
          this.stepActive = 3;
        } else {
          this.$message.error(res.data.message || '编排失败');
        }
      }).catch(err => {
        this.$message.error('编排过程发生错误');
      }).finally(() => {
        this.arrangeLoading = false;
      })
    },

    // 修改移动方法，从移动考区改为移动考场
    moveRoom(index, direction) {
      const newIndex = direction === 'up' ? index - 1 : index + 1;
      if (newIndex >= 0 && newIndex < this.selectedRooms.length) {
        const rooms = [...this.selectedRooms];
        const temp = rooms[index];
        rooms[index] = rooms[newIndex];
        rooms[newIndex] = temp;
        // 更新选中座位的顺序以反映考场顺序的变化
        this.updateSeatsOrder(rooms);
      }
    },

    // 添加更新座位顺序的方法
    updateSeatsOrder(orderedRooms) {
      const [grade_id, unit_id] = this.activeTab.split('_')
      const seatsInfo = this.selectedInfo.find(item => item.grade_id === Number(grade_id) && item.unit_id === Number(unit_id)).seats
      const newSeats = [];
      orderedRooms.forEach(room => {
        const roomSeats = seatsInfo.filter(seat => seat.room_id === room.room_id);
        newSeats.push(...roomSeats);
      });
      this.selectedInfo.find(item => item.grade_id === Number(grade_id) && item.unit_id === Number(unit_id)).seats = newSeats
    },
    resetAndReloadData() {
      // 重置相关数据
      this.selectedSeats = [];
      this.selectedStudents = [];
      this.examAreas = [];
      this.examRoomData = [];
      this.expandedItem = null;
      this.expandedGrade = null;
      this.expandedUnit = null;
      this.checkedStudents = [];
      this.treeData = [];
      this.selectedData = [];
    },

    updateExamTimeData(data) {
      this.examSubjects = data
    },


    // 获取标签标题
    getTabLabel(info) {
      const grade = this.grades.find(g => g.id === info.grade_id)
      const unit = this.units.find(u => u.id === info.unit_id)
      return `${grade?.name || ''}-${unit?.name || ''}`
    },
    initDialogData() {
      this.tabLoading = true
      const firstInfo = this.selectedInfo[0]
      this.activeTab = `${firstInfo.grade_id}_${firstInfo.unit_id}`

      // 重置过滤条件
      this.studentFilter = {
        class: '',
        name: '',
        number: ''
      }
      this.seatFilter = {
        area: '',
        room: '',
        seatNum: ''
      }

      this.$nextTick(() => {
        setTimeout(() => {
          this.tabLoading = false
        }, 200)
      })
    },
    confirmBatchRemove(type) {
      const count = type === 'students' ? this.filteredStudents.length : this.filteredSeats.length
      this.$confirm(
        `确认清除当前筛选的 ${count} ${type === 'students' ? '名学生' : '个座位'}？`,
        '批量清除',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        this.batchRemove(type)
      }).catch(() => {
        this.$message.info('已取消清除')
      })
    },

    // 执行批量删除
    batchRemove(type) {
      if (!this.activeTab) return

      const [gradeId, unitId] = this.activeTab.split('_')
      const data = this.selectedInfo.find(item =>
        item.grade_id === Number(gradeId) &&
        item.unit_id === Number(unitId)
      )

      if (!data) return

      if (type === 'students') {
        // 获取要删除的学生学号列表
        const studentNumbers = this.filteredStudents.map(s => s.student_number)
        // 从数据中移除这些学生
        data.students = data.students.filter(s => !studentNumbers.includes(s.student_number))

        // 更新树的选中状态
        if (this.$refs.tree?.[0]) {
          const currentCheckedKeys = this.$refs.tree[0].getCheckedKeys()
          const keysToRemove = studentNumbers.map(num => num + '-student')
          this.$refs.tree[0].setCheckedKeys(
            currentCheckedKeys.filter(key => !keysToRemove.includes(key) && !key.endsWith('-class'))
          )
        }
      } else {
        // 获取要删除的座位信息
        const seatsToRemove = this.filteredSeats

        // 从数据中移除这些座位
        data.seats = data.seats.filter(seat =>
          !seatsToRemove.some(s =>
            s.room_id === seat.room_id && s.seat_num === seat.seat_num
          )
        )

        // 更新考场座位状态
        this.$nextTick(() => {
          seatsToRemove.forEach(seat => {
            const room = this.examRoomData.find(r => r.room_id === seat.room_id)
            if (room) {
              const targetSeat = room.seats.find(s => s.seat_num === seat.seat_num)
              if (targetSeat) {
                targetSeat.isEmpty = true
                targetSeat.isSelected = false
              }
            }
          })
        })
      }

      this.$message.success(`已清除 ${type === 'students' ? '学生' : '座位'} 数据`)
    },
    backToSelectPage() {
      this.stepActive = 1
      this.$nextTick(() => {
        this.syncSelectedData()
      })
    },
    // searchExam(grade_id, unit_id) {
    //   let params = { grade_id, unit_id }
    //   const keys = grade_id + '_' + unit_id

    //   const { designParams } = this
    //   if (designParams.arrangement_mode === 'rotation') {
    //     params.subject_ids = designParams.arrangement_subjects
    //   }

    //   this.axios.post('/api/examroomdesign/gethistoryexam', params).then(res => {
    //     if (res.data.success) {
    //       this.searchExamList[keys] = res.data.result
    //     }
    //   })
    // },
    toSelectPage() {
      this.stepActive = 0
    },
    findUnitAndGradeInfo(unit_and_grade_info) {
      if (!Array.isArray(unit_and_grade_info)) {
        return ''
      }

      return unit_and_grade_info
        .map(info => {
          const unit = this.units.find(item => item.id === info.unit_id)
          const grade = this.grades.find(item => item.id === info.grade_id)
          return `${unit?.name || ''}-${grade?.name || ''}`
        })
        .filter(name => name)
        .join('、')
    },
    findSubjectName(subject_ids) {
      return subject_ids
        .map(id => this.subjects.find(subject => subject.id === id)?.name || '')
        .filter(name => name)
        .join('，')
    },
    viewArrangement(data) {
      this.arrangementSearchParams.design_id = data.design_id
      this.arrangementSearchParams.exam_id = this.appStore.exam_id
      this.resetSearchViewParams()
      this.showViewDialog = true
      this.getArrangementData(data, 1)
    },
    getArrangementData(data, page) {
      const { arrangementSearchParams: params } = this
      if (data) {
        params.design_id = data.design_id
      }
      if (page) {
        params.page = page
      }
      this.axios.post('/api/examroomdesign/getarrangementdata', params).then(res => {
        if (res.data.success) {
          this.arrangementData = res.data.result.datalist
          params.total = res.data.result.totalrecords
        }
      })
    },
    downloadArrangement(data) {
      this.showDownLoadDialog = true
      this.downloadData.design_id = data.design_id
      this.downloadData.exam_id = this.appStore.exam_id
    },
    setAsScanData(data) {
      const params = { exam_id: this.appStore.exam_id, design_id: data.design_id }

      this.$confirm('确认将此编排数据设置为扫描数据？', '设置扫描数据', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.post('/api/examroomdesign/checkscandata', params).then(res => {
          if (res.data.success) {
            if (res.data.result.inUse) {
              this.$confirm(res.data.result.data, '数据冲突，下列学生已有扫描数据，确认继续将覆盖之前的数据', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
              }).catch(() => {
                return
              })
            }
            this.axios.post('/api/examroomdesign/setasscandata', { exam_id: this.appStore.exam_id, design_id: data.design_id }).then(res => {
              if (res.data.success) {
                this.$message.success('设置成功')
              }
            })
          }
        }).catch(() => {
          return
        })

      })
    },
    deleteArrangement(data) {
      this.$confirm('确认删除此编排数据？', '删除编排数据', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.post('/api/examroomdesign/deletearrangement', { exam_id: this.appStore.exam_id, design_id: data.design_id }).then(res => {
          if (res.data.success) {
            this.$message.success('删除成功')
            this.getDesignData()
          }
        })
      }).catch(() => {
        this.$message.info('已取消删除')
      })
    },
    // 搜索编排数据
    searchArrangementData() {
      // 重置到第一页
      this.arrangementSearchParams.page = 1
      this.getArrangementData(null, 1)
    },

    // 重置搜索参数
    resetSearchViewParams() {
      // 保留必要的参数
      const { design_id } = this.arrangementSearchParams

      // 重置搜索参数
      this.arrangementSearchParams = {
        exam_id: this.appStore.exam_id,
        design_id,
        student_name: '',
        student_number: '',
        class_name: '',
        school_name: '',
        grade_name: '',
        area_name: '',
        room_name: '',
        seat_num: '',
        subject_name: '',
        page: 1,
        page_size: 15,
        total: 0
      }

      // 重新获取数据
      this.getArrangementData(null, null)
    },
    confirmDownload() {
      this.showDownLoading = true;

      this.axios.post('/api/examroomdesign/downloadarrangement', this.downloadData, {
        responseType: 'blob' // 设置响应类型为blob
      }).then(res => {
        // 获取文件名
        const contentDisposition = res.headers['content-disposition'];
        let filename = '考务数据.zip'; // 默认文件名
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename\*?=([^;]*)/);
          if (filenameMatch && filenameMatch[1]) {
            // 尝试从RFC 5987格式解码文件名
            const encodedFilename = filenameMatch[1].replace(/"/g, '');
            filename = decodeURIComponent(encodedFilename);
          }
        }
        // 创建下载链接
        const blob = new Blob([res.data], { type: 'application/zip' });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(downloadLink.href);

        this.$message.success('下载成功');
      }).catch(error => {
        this.$message.error(`下载失败：${error.response && error.response.data ? error.response.data.message : error.message}`);
      }).finally(() => {
        this.showDownLoading = false;
        this.showDownLoadDialog = false;
      })
    },
    handleOrderChange(value) {
      if (value === 'score') {
        this.getScoreExamList()
      }
    },
    getScoreExamList() {
      this.axios.post('/api/examroomdesign/gethistoryexam', { exam_id: this.appStore.exam_id, subject_ids: this.designParams.arrangement_subjects }).then(res => {
        if (res.data.success) {
          this.unitHistoryExam = res.data.result
        }
      })
    }
  }
}

</script>

<template>
  <el-button
              bg text
              icon="ArrowLeft"
              @click="$router.push({name:'exam-examroommge'})"
          >
            返回考场管理
          </el-button>
  <el-steps style="margin: 0 20px auto" :active="stepActive" finish-status="success" :align-center="true">
    <el-step title="参数配置">
      <template #icon>
        <i-setting-two theme="filled" size="24" :strokeWidth="5" />
      </template>
    </el-step>
    <el-step>
      <template #title>
        <span>选择数据</span>
        <div v-if="designParams.arrangement_subjects.length > 0 && stepActive === 1">
          <el-tag style="margin-right: 6px;" type="success" effect="dark" :round="true"
            v-for="subject in designParams.arrangement_subjects"> {{
            subjects.find(item => item.id === subject).name
            }}
          </el-tag>
        </div>
      </template>
      <template #icon>
        <i-check-correct theme="filled" size="24" :strokeWidth="5" />
      </template>
    </el-step>
    <el-step>
      <template #title>
        <span>考场编排</span>
        <div v-if="designParams.arrangement_subjects.length > 0 && stepActive === 2">
          <el-tag style="margin-right: 6px;" type="success" effect="dark" :round="true"
            v-for="subject in designParams.arrangement_subjects"> {{
            subjects.find(item => item.id === subject).name
            }}
          </el-tag>
        </div>
      </template>
      <template #icon>
        <i-association theme="filled" size="24" :strokeWidth="5" />
      </template>
    </el-step>
    <el-step title="导出数据表">
      <template #icon>
        <i-download-computer theme="filled" size="24" :strokeWidth="5" />
      </template>
    </el-step>
  </el-steps>
  <div v-if="stepActive === 0" style="margin: 20px auto;width: 75%;">
    <el-row justify="end" style="margin: 20px 0;">
      <el-button @click="showAreaManage" bg text type="info" icon="Setting">
        考区考场管理
      </el-button>
      <el-button @click="toExportDataPage" bg text type="info" icon="Download">
        数据导出
      </el-button>
    </el-row>
    <ExamTimeManage :exam-id="appStore.exam_id" @updata="updateExamTimeData" />
    <el-card header="参数设置">
      <el-descriptions :column="1" border>
        <el-descriptions-item label="考场模式" align="center">
          <el-radio-group v-model="designParams.arrangement_mode">
            <el-radio-button label="固定考场" value="fixed" />
            <el-radio-button label="轮换考场" value="rotation" />
          </el-radio-group>
          <el-row justify="center">
            <el-text type="info" style="margin: 6px;padding: 0 12px; background-color: #F0F2F5; border-radius: 5px;">
              <i-alarm theme="outline" size="14" style="margin-right: 4px;" />
              固定考场：学生每个科目的考试在同一个考场；轮换考场：学生每个科目的考试将轮换到不同考场
            </el-text>
          </el-row>
        </el-descriptions-item>
        <el-descriptions-item label="编排科目（可多选）" align="center">
          <el-checkbox-group :disabled="designParams.arrangement_mode === ''"
            v-model="designParams.arrangement_subjects">
            <el-checkbox-button v-for="subject in examSubjects" :label="subject.subject_name"
              :value="subject.subject_id" :disabled="disabledSubject(subject)">
              <template #default>
                <el-tooltip offset="24" placement="top" :disabled="!disabledSubject(subject)"
                  content="当前科目未配置考试时间或与其他科目考试时间冲突">
                  <span>{{ subject.subject_name }}</span>
                </el-tooltip>
              </template>
            </el-checkbox-button>
          </el-checkbox-group>
        </el-descriptions-item>
        <el-descriptions-item label="排序方式" align="center">
          <el-radio-group :disabled="designParams.arrangement_subjects.length === 0"
            v-model="designParams.arrangement_order" @change="handleOrderChange">
            <el-radio-button label="成绩优先" value="score" />
            <el-radio-button label="学号优先" value="student_number" />
            <el-radio-button label="完全随机" value="random" />
          </el-radio-group>
          <el-collapse-transition>
            <div v-if="designParams.arrangement_order === 'score'">
              <el-row justify="center">
                <el-text type="info"
                  style="margin: 6px;padding: 0 12px; background-color: #F0F2F5; border-radius: 5px;">
                  <i-alarm theme="outline" size="14" style="margin-right: 4px;" />
                  选择考试成绩作为排序依据
                </el-text>
              </el-row>
              <!-- <el-form label-width="30%">
                <el-form-item v-for="(item, index) in unitGrade" :key="index"
                  :label="`${item.grade_name}-${item.unit_name}`">
                  <el-select remote :remote-method="searchExam(item.grade_id, item.unit_id)" style="width: 70%;"
                    v-model="designParams.arrangement_score_exam[item.grade_id + '_' + item.unit_id]"
                    placeholder="选择考试">
                    <el-option v-for="exam in searchExamList[item.grade_id + '_' + item.unit_id]" :key="exam.exam_id"
                      :label="exam.exam_name" :value="exam.exam_id" />
                  </el-select>
                </el-form-item>
              </el-form> -->
              <el-form label-width="30%">
                <el-form-item v-for="(unitGrade, index) in unitHistoryExam" :key="index"
                  :label="`${unitGrade.grade_name}-${unitGrade.unit_name}`">
                  <el-select style="width: 70%;"
                    v-model="designParams.arrangement_score_exam[unitGrade.grade_id + '_' + unitGrade.unit_id]"
                    placeholder="选择考试">
                    <el-option v-for="exam in unitGrade.exam_list" :key="exam.exam_id" :label="exam.exam_name"
                      :value="exam.exam_id" />
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </el-collapse-transition>
        </el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <el-row justify="end">
          <el-button size="large" text bg type="primary" @click="toCheckDataPage">选择数据</el-button>
        </el-row>
      </template>
    </el-card>
  </div>

  <div v-if="stepActive === 1" style="margin: 20px auto;">
    <div class="step-actions" style="margin: 0 20px 20px;">
      <el-button @click="stepActive = 0" type="primary" plain>
        <el-icon style="margin-right: 4px;">
          <ArrowLeft />
        </el-icon>
        返回科目设置
      </el-button>
      <el-alert type="warning" :closable="false" style="margin-left: 20px;">
        <template #title>
          <i-alarm theme="outline" size="14" fill="#F56C6C" style="margin-right: 4px;" />
          在下方选择需要编排的学生和考场座位后，点击右上角的【下一步】开始编排
        </template>
      </el-alert>
    </div>

    <el-affix :offset="0" style="position: fixed; right: 50px; z-index: 99; top: 90px;">
      <div :class="selectedInfo.length > 0 ? 'affix_selected' : 'affix'" @click="showSelectedData">
        <i-shopping-cart-one theme="filled" size="30" :strokeWidth="2" />
        <div class="affix-text">下一步</div>
        <el-badge v-if="allSeatNumber > 0" :value="allSeatNumber" class="seat-badge" type="danger" :max="99999" />
        <el-badge v-if="allStudentNumber > 0" :value="allStudentNumber" class="student-badge" type="primary"
          :max="99999" />
      </div>
    </el-affix>
    <div v-for="grade in grades" :key="grade.id">
      <div class="grade_card">
        <div class="grade_text">{{ grade.name }}</div>
        <div @click="selecGradetUnit(grade, unit)" class="school_card" v-for="unit in units" :key="unit.id">
          <span :class="isExpandedItem(this.expandedItem, grade, unit) ? 'school_text_active' : 'school_text'">
            {{ unit.name }}
            <i-right :class="isExpandedItem(this.expandedItem, grade, unit) ? 'i_right_active' : 'i_right'"
              theme="filled" size="16" :strokeWidth="2" />
          </span>
        </div>
      </div>
      <el-collapse-transition>
        <div v-if="expandedGrade && expandedGrade.id === grade.id">
          <el-container>
            <el-aside>
              <div>
                <h3 style="text-align: center">{{ unit.name }} - {{ grade.name }}</h3>
                <h3 style="text-align: center">学生列表</h3>
              </div>

              <el-row justify="end" style="margin: 6px 0">
                <el-button-group v-if="this.treeData.length > 0">
                  <el-button type="primary" text bg @click="checkedUnDesigned">
                    选中未编排学生
                  </el-button>
                  <el-button style="margin-left: 6px;" type="danger" text bg @click="cancelAllCheckedStudents">
                    取消所有选中
                  </el-button>
                </el-button-group>
              </el-row>
              <el-tree-v2 ref="tree" style="height: calc(100vh - 300px);" show-checkbox :props="treeProps"
                :data="treeData" :height="800" @check="handleStudentCheck" @check-change="handleCheckChange">
                <template #default="{ node, data }">
                  <el-tooltip v-if="data.isDesigned !== undefined" effect="light" placement="right">
                    <template #default>
                      <span v-if="data.isDesigned && data.isFull" style="color: #67C23A">
                        {{ data.name }}
                      </span>
                      <span v-else-if="data.isDesignde && !data.isFull" style="color: #E6A23C">
                        {{ data.name }}
                      </span>
                      <span v-else style="color: #909399">
                        {{ data.name }}
                      </span>
                    </template>
                    <template #content>
                      <span v-if="data.isDesigned && data.isFull" style="color: #67C23A">
                        <div v-for="(seat, index) in data.seats" :key="index">
                          {{ seat.subject_name }} &nbsp;
                          <i-check-one theme="filled" size="14" :strokeWidth="2" />
                          &nbsp;
                          考场：{{ seat.room_name }} &nbsp; 座位号：{{ seat.seat_num }}
                        </div>
                      </span>
                      <span v-else-if="data.isDesigned && !data.isFull" style="color: #E6A23C">
                        <div v-for="(seat, index) in data.seats" :key="index">
                          <span v-if="seat.isDesigned">
                            {{ seat.subject_name }} &nbsp;
                            <i-check-one theme="filled" size="14" :strokeWidth="2" />
                            &nbsp;
                            考场：{{ seat.room_name }} &nbsp; 座位号：{{ seat.seat_num }}
                          </span>
                          <span v-else>
                            {{ seat.subject_name }} &nbsp;
                            <i-attention theme="filled" size="14" :strokeWidth="2" />
                            暂无座位号
                          </span>
                        </div>
                      </span>
                      <span v-else style="color: #909399">
                        <div v-for="(seat, index) in data.seats" :key="index">
                          {{ seat.subject_name }} &nbsp;
                          <i-attention theme="filled" size="14" :strokeWidth="2" />
                          暂无座位号
                        </div>
                      </span>
                    </template>
                  </el-tooltip>
                  <span v-else>{{ data.name }}</span>
                </template>
              </el-tree-v2>
            </el-aside>
            <el-main>
              <el-container>
                <el-header>
                  <span v-if="expandedUnit" class="header_unit"> {{ expandedUnit.name }} </span>
                  <span v-if="expandedGrade" class="header_grade"> {{ expandedGrade.name }} </span>
                </el-header>
                <el-main>
                  <el-collapse>
                    <el-collapse-item v-for="area in examAreas" :key="area.area_id" :title="area.area_name">
                      <template #title>
                        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%">
                          <span class="area-title">{{ area.area_name }}</span>
                          <div class="badge-wrapper">
                            <el-badge :value="getAreaSelectedCount(area)" :hidden="!getAreaSelectedCount(area)"
                              type="danger" :max="99999">
                              <el-button-group>
                                <el-button type="primary" size="small" @click.stop="selectAllEmptySeatsInArea(area)">
                                  选择空闲座位
                                </el-button>
                                <el-button type="danger" size="small" @click.stop="cancelAllSelectedSeatsInArea(area)">
                                  取消所有选择
                                </el-button>
                              </el-button-group>
                            </el-badge>
                          </div>
                        </div>
                      </template>
                      <div class="room-table-header">
                        <div class="room-table-cell">考场名称</div>
                        <div class="room-table-cell">考场别名</div>
                        <div class="room-table-cell">考场号</div>
                        <div class="room-table-cell">考场容量</div>
                        <div class="room-table-cell">批量操作</div>
                      </div>
                      <div v-for="room in examRoomData.filter(room => room.area_id === area.area_id)"
                        :key="room.room_id">
                        <el-collapse class="room-collapse">
                          <el-collapse-item class="room-collapse-item">
                            <template #title>
                              <div class="room-table-row">
                                <div class="room-table-cell">{{ room.room_name }}</div>
                                <div class="room-table-cell">{{ room.room_nick_name }}</div>
                                <div class="room-table-cell">{{ room.room_number }}</div>
                                <div class="room-table-cell">{{ room.room_capacity }}</div>
                                <div class="room-table-cell">
                                  <div class="badge-wrapper">
                                    <el-badge :value="getRoomSelectedCount(room)" :hidden="!getRoomSelectedCount(room)"
                                      type="danger" :max="99999">
                                      <el-button-group>
                                        <el-button type="primary" size="small"
                                          @click.stop="selectAllEmptySeatsInRoom(room)">
                                          选中空座位
                                        </el-button>
                                        <el-button type="danger" size="small"
                                          @click.stop="cancelAllSelectedSeatsInRoom(room)">
                                          取消所有选择
                                        </el-button>
                                      </el-button-group>
                                    </el-badge>
                                  </div>
                                </div>
                              </div>
                            </template>
                            <div style="padding: 12px">
                              <div class="classroom">
                                <div class="platform-container">
                                  <div class="platform">讲台</div>
                                  <div class="operation-tips">
                                    <div class="tip-item">
                                      <div class="tip-dot empty"></div>
                                      <span>空闲座位（可点击选择）</span>
                                    </div>
                                    <div class="tip-item">
                                      <div class="tip-dot selected"></div>
                                      <span>已选座位（可点击取消）</span>
                                    </div>
                                    <div class="tip-item">
                                      <div class="tip-dot occupied"></div>
                                      <span>已占用座位</span>
                                    </div>
                                    <div class="tip-item">
                                      <i-mouse-one theme="filled" size="14" :strokeWidth="2" />
                                      <span>按住鼠标左键拖动可快速选择</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="seats-container" @mousemove="handleMouseMove($event, room)"
                                  @mouseup="handleMouseUp">
                                  <div class="seats-grid">
                                    <div v-for="seat in room.seats" :key="seat.seat_num" class="seat" :class="{
                                      'seat-empty': seat.isEmpty,
                                      'seat-selected': seat.isSelected,
                                      'seat-occupied': !seat.isEmpty && !seat.isSelected
                                    }" @mousedown.stop="handleMouseDown($event, room, seat)">
                                      <div class="seat-number">{{ seat.seat_num }}</div>
                                      <div class="seat-status">
                                        <template v-if="seat.isEmpty && !seat.isSelected">
                                          空闲
                                        </template>
                                        <template v-else-if="seat.isSelected">
                                          已选
                                        </template>
                                        <template v-else>
                                          已占
                                        </template>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </el-collapse-item>
                        </el-collapse>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </el-main>
              </el-container>
            </el-main>
          </el-container>
        </div>
      </el-collapse-transition>
    </div>
  </div>

  <el-dialog align-center v-model="selectedDataDialog" title="已选数据" width="90%" style="min-height: 50vh;"
    :destroy-on-close="true">
    <el-empty v-if="!selectedInfo.length" style="height: 40vh;" description="暂无已选数据" />
    <el-tabs v-model="activeTab">
      <el-tab-pane v-for="info in selectedInfo" :key="`${info.grade_id}_${info.unit_id}`" :label="getTabLabel(info)"
        :name="`${info.grade_id}_${info.unit_id}`">
        <el-container class="dialog-container" v-loading="tabLoading" element-loading-text="加载中...">
          <template v-if="!tabLoading">
            <el-aside width="50%" class="dialog-aside">
              <h3 style="text-align: center">学生信息</h3>
              <el-form class="filter-form" label-width="80px">
                <el-form-item label="班级">
                  <el-select v-model="studentFilter.class" placeholder="选择班级" clearable>
                    <el-option v-for="className in currentClassOptions" :key="className" :label="className"
                      :value="className" />
                  </el-select>
                </el-form-item>
                <el-form-item label="姓名">
                  <el-input v-model="studentFilter.name" placeholder="输入姓名" clearable />
                </el-form-item>
                <el-form-item label="学号">
                  <el-input v-model="studentFilter.number" placeholder="输入学号" clearable />
                </el-form-item>
                <div class="filter-actions">
                  <el-button type="danger" size="small" @click="confirmBatchRemove('students')"
                    :disabled="!filteredStudents.length">
                    批量清除
                  </el-button>
                </div>
              </el-form>
              <div class="table-wrapper">
                <el-table :data="filteredStudents" border height="100%" :row-key="row => row.student_number"
                  :virtual-scrolling="true" :scroll-row-height="48">
                  <el-table-column prop="student_name" label="姓名" align="center" />
                  <el-table-column prop="student_number" label="学号" align="center" />
                  <el-table-column prop="class_name" label="班级" align="center" />
                  <el-table-column label="操作" width="120" align="center">
                    <template #default="scope">
                      <el-button type="danger" size="small" @click="removeSelectedStudent(scope.row, activeTab)">
                        移除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-aside>
            <el-main class="dialog-main">
              <h3 style="text-align: center">考场位信息</h3>
              <el-form class="filter-form" label-width="80px">
                <el-form-item label="考区">
                  <el-select v-model="seatFilter.area" placeholder="选择考区" clearable>
                    <el-option v-for="areaName in currentAreaOptions" :key="areaName" :label="areaName"
                      :value="areaName" />
                  </el-select>
                </el-form-item>
                <el-form-item label="考场">
                  <el-select v-model="seatFilter.room" placeholder="选择考场" clearable>
                    <el-option v-for="roomName in currentRoomOptions" :key="roomName" :label="roomName"
                      :value="roomName" />
                  </el-select>
                </el-form-item>
                <el-form-item label="座位号">
                  <el-input v-model="seatFilter.seatNum" placeholder="输入座位号" clearable />
                </el-form-item>
                <div class="filter-actions">
                  <el-button type="danger" size="small" @click="confirmBatchRemove('seats')"
                    :disabled="!filteredSeats.length">
                    批量清除
                  </el-button>
                </div>
              </el-form>
              <div class="table-wrapper">
                <el-table :data="filteredSeats" border height="100%" :row-key="row => `${row.room_id}_${row.seat_num}`"
                  :virtual-scrolling="true" :scroll-row-height="48">
                  <el-table-column prop="area_name" label="考区" align="center" />
                  <el-table-column prop="room_name" label="考场" align="center" />
                  <el-table-column prop="seat_num" label="座位号" align="center" />
                  <el-table-column label="操作" align="center">
                    <template #default="scope">
                      <el-button @click="removeSelectedSeat(scope.row, activeTab)" type="danger" size="small">
                        移除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-main>
          </template>
          <template v-else>
            <div style="width: 100%; height: calc(100vh - 250px);"></div>
          </template>
        </el-container>
      </el-tab-pane>
    </el-tabs>
    <template #footer>
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <div>
          <span class="statistics-text">已选学生：{{ currentTabData.students?.length || 0 }} 人</span>
          <span class="statistics-text">已选座位：{{ currentTabData.seats?.length || 0 }} 个</span>
        </div>
        <div>
          <el-button @click="selectedDataDialog = false">取消</el-button>
          <el-button type="primary" @click="startArrangement" :disabled="!canStartArrangement">
            开始编排
          </el-button>
        </div>
      </div>
    </template>
  </el-dialog>

  <el-dialog v-model="areaManageDialog" title="考区考场管理" width="80%" :destroy-on-close="true">
    <ExamAreaManage :units="units" />
  </el-dialog>

  <div v-if="stepActive === 2" class="arrangement-page">
    <el-card class="arrangement-card">
      <template #header>
        <div class="card-header">
          <span>编排设置</span>
          <el-button-group>
            <el-button @click="backToSelectPage">返回选择</el-button>
            <el-button type="primary" @click="executeArrangement" :loading="arrangeLoading">
              开始编排
            </el-button>
          </el-button-group>
        </div>
      </template>

      <el-tabs v-model="activeTab">
        <el-tab-pane v-for="info in selectedInfo" :key="`${info.grade_id}_${info.unit_id}`" :label="getTabLabel(info)"
          :name="`${info.grade_id}_${info.unit_id}`">
          <h3>考场编排顺序</h3>
          <el-table :data="selectedRooms" style="width: 100%" border>
            <el-table-column type="index" label="序号" width="80" align="center" />
            <el-table-column prop="area_name" label="考区" align="center" />
            <el-table-column prop="room_name" label="考场" align="center" />
            <el-table-column label="座位数量" align="center">
              <template #default="{ row }">
                {{ row.seats.length }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120" align="center">
              <template #default="{ $index }">
                <el-button-group>
                  <el-button type="primary" circle :disabled="$index === 0" @click="moveRoom($index, 'up')">
                    <el-icon>
                      <ArrowUp />
                    </el-icon>
                  </el-button>
                  <el-button type="primary" circle :disabled="$index === selectedRooms.length - 1"
                    @click="moveRoom($index, 'down')">
                    <el-icon>
                      <ArrowDown />
                    </el-icon>
                  </el-button>
                </el-button-group>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div class="statistics-panel">
        <el-descriptions :column="3" border>
          <el-descriptions-item label="待编排学生">
            {{ currentTabData.students?.length || 0 }} 人
          </el-descriptions-item>
          <el-descriptions-item label="可用座位">
            {{ currentTabData.seats?.length || 0 }} 个
          </el-descriptions-item>
          <el-descriptions-item label="考场数量">
            {{ selectedRooms.length }} 个
          </el-descriptions-item>
          <el-descriptions-item label="总待编排学生">
            {{ allStudentNumber }} 人
          </el-descriptions-item>
          <el-descriptions-item label="总可用座位">
            {{ allSeatNumber }} 个
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-card>
  </div>
  <div v-if="stepActive === 3" style="margin: 20px auto;width: 75%;">
    <el-row justify="end">
      <el-button type="info" @click="toSelectPage" text bg icon="ArrowLeft">返回参数配置</el-button>
    </el-row>
    <el-table :data="designData" border>
      <el-table-column prop="" align="center" width="50">
        <template #default="{ row }">
          <span v-if="row.is_scan_data === 1">
            <el-tooltip content="已设置为扫描数据" placement="top">
              <i-star theme="filled" size="18" fill="#E6A23C" />
            </el-tooltip>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="last_edit_time" label="编排时间" align="center" />
      <el-table-column prop="arrangement_mode" label="考场模式" align="center">
        <template #default="{ row }">
          {{ row.arrangement_mode === "fixed" ? '固定考场' : '轮换考场' }}
        </template>
      </el-table-column>
      <el-table-column prop="arrangement_subject" label="编排科目" align="center">
        <template #default="{ row }">
          {{ findSubjectName(row.arrangement_subjects) }}
        </template>
      </el-table-column>
      <el-table-column prop="arrangement_order" label="排序方式" align="center">
        <template #default="{ row }">
          <span v-if="row.arrangement_order === 'student_score'">成绩优先</span>
          <span v-if="row.arrangement_order === 'student_number'">学号优先</span>
          <span v-if="row.arrangement_order === 'random'">完全随机</span>
        </template>
      </el-table-column>
      <el-table-column prop="unit_and_grade_info" label="参与学校和年级" align="center">
        <template #default="{ row }">
          {{ findUnitAndGradeInfo(row.unit_and_grade_info) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="{ row }">
          <el-button class="operation-button" type="info" bg text icon="View"
            @click="viewArrangement(row)">查看</el-button>
          <el-button class="operation-button" type="primary" bg text icon="Download"
            @click="downloadArrangement(row)">导出</el-button>
          <el-button class="operation-button" type="danger" bg text icon="Delete"
            @click="deleteArrangement(row)">删除</el-button>
          <el-button class="operation-button" type="success" bg text round icon="Setting" v-if="row.is_scan_data === 0"
            @click="setAsScanData(row)">设为考场扫描数据</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog v-model="showViewDialog" title="查看编排数据" width="80%" :destroy-on-close="true">
      <el-row :gutter="10" justify="end">
        <el-col :span="2">
          <el-input v-model="arrangementSearchParams.student_name" placeholder="输入姓名" clearable />
        </el-col>
        <el-col :span="2">
          <el-input v-model="arrangementSearchParams.student_number" placeholder="输入学号" clearable />
        </el-col>
        <el-col :span="3">
          <el-input v-model="arrangementSearchParams.school_name" placeholder="输入学校" clearable />
        </el-col>
        <el-col :span="3">
          <el-input v-model="arrangementSearchParams.grade_name" placeholder="输入年级" clearable />
        </el-col>
        <el-col :span="3">
          <el-input v-model="arrangementSearchParams.class_name" placeholder="输入班级" clearable />
        </el-col>
        <el-col :span="3">
          <el-input v-model="arrangementSearchParams.subject_name" placeholder="输入科目" clearable />
        </el-col>
        <el-col :span="3">
          <el-input v-model="arrangementSearchParams.area_name" placeholder="输入考区" clearable />
        </el-col>
        <el-col :span="3">
          <el-input v-model="arrangementSearchParams.room_name" placeholder="输入考场" clearable />
        </el-col>
        <el-col :span="2">
          <el-input v-model="arrangementSearchParams.seat_num" placeholder="输入座位号" clearable />
        </el-col>
        <el-col :span="3" style="margin: 10px 0;display: flex;justify-content: end;align-items: center;">
          <el-button type="primary" @click="searchArrangementData" icon="Search">搜索</el-button>
          <el-button type="warning" @click="resetSearchViewParams" icon="Refresh">重置</el-button>
        </el-col>
      </el-row>
      <el-table :data="arrangementData" border>
        <el-table-column prop="student_name" label="姓名" align="center" />
        <el-table-column prop="student_number" label="学号" align="center" />
        <el-table-column prop="school_name" label="学校" align="center" />
        <el-table-column prop="grade_name" label="年级" align="center" />
        <el-table-column prop="class_name" label="班级" align="center" />
        <el-table-column prop="subject_name" label="科目" align="center" />
        <el-table-column prop="area_name" label="考区" align="center" />
        <el-table-column prop="room_name" label="考场" align="center" />
        <el-table-column prop="seat_num" label="座位号" align="center" />
      </el-table>
      <el-pagination @current-change="getArrangementData(null, $event)" :current-page="arrangementSearchParams.page"
        :page-size="arrangementSearchParams.page_size" layout="total, prev, pager, next, jumper"
        :total="arrangementSearchParams.total" />
    </el-dialog>
    <el-drawer v-model="showDownLoadDialog" direction="rtl" :destroy-on-close="true">
      <template #header>
        <h4>考务数据导出</h4>
      </template>
      <template #default>
        <div>
          <h4>
            <span>
              <span>导出数据</span>
              <span style="color: red;">*</span>
            </span>
          </h4>
          <el-checkbox-group v-model="downloadData.checkList">
            <el-checkbox label="桌贴" value="table_label" />
            <el-checkbox label="门贴" value="door_label" />
            <el-checkbox label="签到表" value="sign_in_table" />
            <el-checkbox label="考区考场表" value="area_room_table" />
            <el-checkbox label="学生考试信息表" value="student_exam_info_table" />
            <el-checkbox label="考试时间表" value="exam_time_table" />
          </el-checkbox-group>
        </div>
      </template>
      <template #footer>
        <div style="flex: auto">
          <el-button @click="showDownLoadDialog = false">关闭</el-button>
          <el-button type="primary" :disabled="!downloadData.checkList.length" @click="confirmDownload"
            :loading="showDownLoading">确认</el-button>
        </div>
      </template>
    </el-drawer>
  </div>

</template>

<style scoped>
.grade_card {
  padding: 12px;
  border: 1px solid #EBEEF5;
  border-radius: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.grade_card:hover {
  border-color: rgb(148.6, 212.3, 117.1);
}

.grade_text {
  padding: 0 12px;
  margin-right: 40px;
  color: rgb(82.4, 155.2, 46.4);
}

.school_card {
  display: flex;
}

.school_text {
  cursor: pointer;
  color: #303133;
  font-weight: bold;
  margin: 12px;
  padding: 10px;
  border: 1px solid #EBEEF5;
  border-radius: 12px;
}

.school_text:hover {
  color: #409EFF;
  border: 1px solid #409EFF;
}

.school_text_active {
  cursor: pointer;
  font-weight: bold;
  margin: 12px;
  padding: 10px;
  border-radius: 12px;
  color: #409EFF;
  border: 1px solid #409EFF;
}

.header_unit {
  margin: 0 12px;
  color: #409EFF;
  font-weight: bold;
}

.header_grade {
  margin: 0 12px;
  color: #67C23A;
  font-weight: bold;
}

.i_right {
  transition: transform 0.4s;
}

.i_right_active {
  transition: transform 0.8s;
  transform: rotate(90deg);
}

/* 修改 affix 样式 */
.affix {
  padding: 12px;
  width: 70px;
  height: 70px;
  background: #ffffff;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 0 10px #CDD0D6;
  transition: background 0.5s ease, box-shadow 0.5s ease;
  color: #303133;
  position: relative;
}

.affix:hover {
  background: #909399;
  color: white;
  box-shadow: 0 0 20px #909399;
}

.affix_selected {
  padding: 12px;
  width: 70px;
  height: 70px;
  background: #ffffff;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  transition: background 0.5s ease, box-shadow 0.5s ease;
  color: #303133;
  position: relative;
  box-shadow: 0 0 10px rgb(250, 181.5, 181.5);
}

.affix_selected:hover {
  background: #909399;
  color: white;
  box-shadow: 0 0 20px #909399;
}


/* 调整浮窗徽章位置 */
.seat-badge {
  position: absolute !important;
  top: 4px !important;
  right: 4px !important;
  transform: translate(30%, -30%) !important;
}

.student-badge {
  position: absolute !important;
  top: 4px !important;
  left: 4px !important;
  transform: translate(-30%, -30%) !important;
}

.affix-text {
  font-size: 14px;
  font-weight: bold;
}

.selected-seats-count {
  display: none;
}

.classroom {
  padding: 20px;
  background: #f5f7fa;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  /* 防止内容溢出 */
}

.platform-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  width: 100%;
}

.platform {
  width: 200px;
  height: 40px;
  background: #909399;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
}

.seats-container {
  padding: 20px;
  padding-top: 8px;
  background-color: #e1e1e1;
  border-radius: 4px;
  width: fit-content;
  /* 修改为 fit-content */
  margin: 0 auto;
  /* 保持水平居中 */
  display: flex;
  justify-content: center;
}

.seats-grid {
  display: grid;
  grid-template-columns: repeat(15, 80px);
  gap: 15px;
  width: fit-content;
  padding: 15px;
  border-radius: 4px;
  user-select: none;
}

.seat {
  width: 80px;
  height: 80px;
  min-width: 80px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  user-select: none;
}

.seat-empty {
  background: #ffffff;
  border-color: #dcdfe6;
  color: #303133;
}

.seat-empty:hover {
  border-color: #409EFF;
  transform: translateY(-2px);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.seat-selected {
  background: #67C23A;
  color: white;
  border-color: #67C23A;
  cursor: pointer;
}

.seat-selected:hover {
  border-color: #F56C6C;
  transform: translateY(-2px);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.seat-occupied {
  background: #F56C6C;
  color: white;
  border-color: #F56C6C;
  cursor: not-allowed;
}

.seat-status {
  font-size: 12px;
}

.room-info {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
}

.room-info span {
  font-size: 14px;
}

/* 表格样式 */
.room-table-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: calc(100% - 40px);
  text-align: center;
  border-left: 1px solid #EBEEF5;
  border-top: none;
}

.room-table-cell {
  padding: 10px 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: #606266;
  font-weight: normal;
}

.room-table-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: calc(100% - 40px);
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: #606266;
  border: 1px solid #EBEEF5;
  margin-right: 40px;
}

.room-table-header .room-table-cell {
  padding: 4px;
  border-right: 1px solid #EBEEF5;
  color: #606266;
  font-weight: bold;
}

.room-table-header .room-table-cell:last-child {
  border-right: none;
}

.selected-seats-count {
  display: none;
}

.area-title {
  font-size: 16px;
  font-weight: bold;
  padding: 8px 12px;
  color: #303133;
}

.operation-tips {
  display: flex;
  flex-wrap: wrap;
  /* 添加换行 */
  gap: 12px;
  /* 减小间距 */
  justify-content: flex-end;
  margin: 8px 20px;
  width: calc(100% - 40px);
  /* 减去左右margin */
}

.tip-item {
  display: flex;
  align-items: center;
  gap: 6px;
  /* 减小图标和文字的间距 */
  font-size: 13px;
  color: #606266;
  white-space: nowrap;
  /* 防止文字换行 */
}

.tip-dot {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 2px solid transparent;
}

.tip-dot.empty {
  background: #ffffff;
  border-color: #dcdfe6;
}

.tip-dot.selected {
  background: #67C23A;
  border-color: #67C23A;
}

.tip-dot.occupied {
  background: #F56C6C;
  border-color: #F56C6C;
}

.room-collapse {
  border: none;
}

.room-collapse :deep(.el-collapse-item__header):hover {
  background: #f5f7fa;
}

.table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.arrangement-aside {
  padding: 15px;
}

.student-item {
  padding: 8px 0;
}

.student-name {
  font-weight: bold;
  margin-bottom: 4px;
}

.student-info {
  font-size: 12px;
  color: #909399;
}

.classroom-preview {
  width: 100%;
  padding: 20px;
  background: #f5f7fa;
  border-radius: 8px;
}

.platform {
  width: 200px;
  height: 40px;
  background: #909399;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  border-radius: 4px;
}


.seat-item {
  aspect-ratio: 1;
  background: white;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.seat-available:hover {
  border-color: #409EFF;
  transform: translateY(-2px);
}

.seat-number {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}

.seat-student {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.seat-student-info {
  font-size: 12px;
  color: #909399;
  font-weight: normal;
  margin-top: 2px;
}

.arrangement-page {
  padding: 20px;
}

.arrangement-card {
  max-width: 1200px;
  margin: 0 auto;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrangement-order {
  border: 1px solid #EBEEF5;
  border-radius: 4px;
  padding: 10px;
}

.statistics-panel {
  margin-top: 20px;
  padding: 20px;
  background: #f5f7fa;
  border-radius: 4px;
}

.arrangement-order {
  border: 1px solid #EBEEF5;
  border-radius: 4px;
  padding: 10px;
}

.badge-wrapper {
  position: relative;
  display: inline-flex;
  margin-right: 30px;
}


.step-actions {
  display: flex;
  align-items: center;
  gap: 12px;
}

/* 确保图标垂直居中 */
.step-actions .el-icon {
  vertical-align: middle;
}

/* 调整 aside 容器的样式 */
.el-aside {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  padding: 20px !important;
  border-right: 1px solid #e4e7ed;
}

.dialog-main {
  width: 50%;
}

.table-wrapper {
  height: 600px;
  overflow: auto;
}

.table-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 0;
  /* 重要：防止flex子元素溢出 */
}

.table-wrapper :deep(.el-table) {
  flex: 1;
  width: 100% !important;
  /* 强制表格宽度 */
}

.table-wrapper :deep(.el-table__inner-wrapper) {
  height: 100%;
  /* 确保内部包装器填充容器 */
}

/* 优化加载状态的显示 */
.el-container {
  position: relative;
  min-height: 600px;
  display: flex;
}

:deep(.el-loading-mask) {
  background-color: rgba(255, 255, 255, 0.95);
  transition: opacity 0.2s;
}

:deep(.el-loading-spinner) {
  .el-loading-text {
    color: #409EFF;
    font-size: 14px;
    margin: 3px 0;
  }
}

.table-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.table-wrapper :deep(.el-table) {
  height: 100%;
}

.table-wrapper :deep(.el-table__inner-wrapper) {
  height: 100%;
}

.table-wrapper :deep(.el-table__body-wrapper) {
  overflow-y: scroll;
}

/* 优化加载状态显示 */
:deep(.el-loading-mask) {
  background-color: rgba(255, 255, 255, 0.9);
  transition: opacity 0.1s;
}

:deep(.el-loading-spinner) {
  transition: all 0.1s;
}

/* 添加过渡效果 */
.el-tab-pane {
  transition: opacity 0.2s;
}


.table-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
}


/* 修改容器样式 */
.dialog-container {
  height: calc(100vh - 250px);
  min-height: 700px;
  width: 100%;
  display: flex;
}

.dialog-aside,
.dialog-main {
  width: 50%;
  height: 100%;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: hidden;
}

.table-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 0;
  overflow: hidden;
}

.table-wrapper :deep(.el-table) {
  flex: 1;
  height: 100%;
}

.table-wrapper :deep(.el-table__inner-wrapper) {
  height: 100%;
}

.table-wrapper :deep(.el-table__body-wrapper) {
  overflow-y: auto;
  overflow-x: hidden;
  /* 隐藏横向滚动条 */
}

/* 隐藏表格右侧的空白列 */
.table-wrapper :deep(.el-table__cell.gutter) {
  display: none;
}

/* 确保表格不会超出容器宽度 */
.table-wrapper :deep(.el-table__header),
.table-wrapper :deep(.el-table__body) {
  width: 100% !important;
}

.filter-form {
  margin: 12px 0;
  padding: 12px;
  background: #f5f7fa;
  border-radius: 12px;
}

.filter-actions {
  display: flex;
  justify-content: flex-end;
}

.operation-button {
  margin: 0 12px 12px 0;
}
</style>
