<script>
import {useUserStoreWithOut} from "@/store/modules/user";
import axios from "axios";

export default {
  name: "CardMenu",
  data() {
    return {
      load: false,
      userTypes:[],
      examMgeMenuExist: false,
      teacherMarkingMenuExist: false,
      bindAccountFormVisi: false,
      accountTypelist: ["我是学生","我是老师"],
      accountCurrentType: "我是学生",
      studentFormData:{
        unit_code: "",
        student_number: "",
        student_password: ""
      },
    }
  },
  methods:{
    // 绑定账号
    async bindAccount(){
      if (this.accountCurrentType === "我是学生"){
        if(!this.studentFormData.unit_code){
          this.$message.warning('请填写学校编号')
          return
        }
        if(!this.studentFormData.student_number){
          this.$message.warning('请填写学号')
          return
        }
        if(!this.studentFormData.student_password){
          this.$message.warning('请填写密码')
          return
        }
        const {data} = await axios.post("/api/login/bindAccount", {...this.studentFormData,type:"1"})
        if (data.success === true){
          this.$message.success("绑定成功！请重新登录")
          this.userStore.logout()
          this.$router.push({name:"login"})
        }

      }
    }
  },
  watch:{
    'userStore.pages':{
      handler (newVal) {
        this.load = false
        this.examMgeMenuExist = false
        this.teacherMarkingMenuExist = false
        for (let i = 0; i < newVal.length; i++) {
          const p = newVal[i]
          if (p.permission_url == "/exam/mgeexam"){
            this.examMgeMenuExist = true
          }
          if (p.permission_url == "/marking"){
            this.teacherMarkingMenuExist = true
          }
        }
        this.load = true
      },
      immediate: true
    },
    'userStore.user': {
      handler(user){
        if (user.type == '-1'){
          this.bindAccountFormVisi = true
        }
      },
      immediate: true
    }
  },
  setup() {
    const userStore = useUserStoreWithOut()
    return {userStore}
  },
}
</script>

<template>
  <div>
    <el-space wrap v-if="load && userStore.user.type != -1">
      <div :class="examMgeMenuExist ? 'cookieCard' : 'cookieCard-disabled'"
           @click="() =>{
           if(examMgeMenuExist) $router.replace({name:'exam'})
         } "
      >
        <p class="cookieHeading">考试管理</p>
        <p class="cookieDescription">从考试答题卡配置开始，到分配权限、评卷进度质量监控一系列流程的管理</p>
      </div>
      <div :class="teacherMarkingMenuExist ? 'cookieCard2' : 'cookieCard-disabled'"
           @click="() => {
           if(teacherMarkingMenuExist)$router.replace({name:'marking'})
         }"
      >
        <p class="cookieHeading">教师评卷</p>
        <p class="cookieDescription">提高评卷效率，提供更加灵活和安全的评卷环境</p>
      </div>
      <!--4普通用户，他们目前没有权限查看考试的统计分析-->
      <div :class="!['4'].includes(userStore.user.type) ? 'cookieCard3' : 'cookieCard-disabled'" @click="
    () =>{
      if(!['4'].includes(userStore.user.type)){
        $router.replace({name:'analysis'})
      }
    }
    ">
        <p class="cookieHeading">成绩分析</p>
        <p class="cookieDescription">支持按市、区、县等多地区的统计与分析，对学生多学科生成统计报表</p>
      </div>
    </el-space>
    <el-dialog v-model="bindAccountFormVisi" title="绑定账号" width="500"  :show-close="false" :close-on-click-modal="false">
      <el-segmented v-model="accountCurrentType" :options="accountTypelist" size="large" style="width: 100%;margin-bottom: 10px"/>
      <el-form :model="studentFormData" v-show="accountCurrentType === '我是学生'" label-width="100">
        <el-form-item label="学校编号">
          <el-input v-model="studentFormData.unit_code" autocomplete="off" />
        </el-form-item>
        <el-form-item label="学号">
          <el-input v-model="studentFormData.student_number" autocomplete="off" />
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="studentFormData.student_password" autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="bindAccount()">
            绑定
          </el-button>
          <el-button  @click="() => userStore.logoutConfirm()">
              <span>
                <i-logout theme="two-tone" size="14" :fill="['#333' ,'#b8e986']"/>
                退出
              </span>
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped>
.cookieCard {
  width: 300px;
  height: 150px;
  background: linear-gradient(to right,var(--el-color-primary),var(--el-color-primary-light-3));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.cookieCard-disabled {
  width: 300px;
  height: 150px;
  background: linear-gradient(to right,var(--el-color-info),var(--el-color-info-light-3));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  position: relative;
  overflow: hidden;
  cursor: not-allowed;
}

.cookieCard2 {
  width: 300px;
  height: 150px;
  background: linear-gradient(to right,var(--el-color-success),var(--el-color-success-light-3));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.cookieCard3 {
  width: 300px;
  height: 150px;
  background: linear-gradient(to right,rgb(137, 104, 255),rgb(175, 152, 255));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
/*向上缓慢浮动,只播放一次*/
.cookieCard2:hover,.cookieCard:hover,.cookieCard3:hover{
  animation: floatUp .4s ease-in-out;
  animation-fill-mode: forwards; /* 保持动画结束时的状态 */

}

@keyframes floatUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-4px);
  }
}

.cookieCard::before {
  width: 150px;
  height: 150px;
  content: "";
  background: linear-gradient(to right,var(--el-color-primary),var(--el-color-primary-light-3));
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  right: -25%;
  top: -25%;
}
.cookieCard2::before {
  width: 150px;
  height: 150px;
  content: "";
  background: linear-gradient(to right,var(--el-color-success),var(--el-color-success-light-3));
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  right: -25%;
  top: -25%;
}

.cookieCard3::before {
  width: 150px;
  height: 150px;
  content: "";
  background: linear-gradient(to right,rgb(137, 104, 255),rgb(175, 152, 255));
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  right: -25%;
  top: -25%;
}

.cookieHeading {
  font-size: 1.5em;
  font-weight: 600;
  color: rgb(241, 241, 241);
  z-index: 2;
}

.cookieDescription {
  font-size: 0.9em;
  color: rgb(241, 241, 241);
  z-index: 2;
}

.cookieDescription a {
  color: rgb(241, 241, 241);
}
</style>