<!--
参考学生管理界面
最后编辑人：宁茂
最后编辑时间：2022-5-25
最后编辑细节：功能优化
-->
<template>
  <el-container class="base-container" v-loading="loading">
    <el-container class="main-container" ref="mainSector" >
      <el-container style="height: calc(100% - 32px);">
        <el-main>
          <div class="row">
            <el-input siz="default" placeholder="请输入学生姓名" v-model="search1.stuname" @keyup.enter="toSearch1" style="width: 200px; min-width: 80px;">
            </el-input>
            <el-input siz="default" placeholder="请输入学生学号" v-model="search1.stunum" @keyup.enter="toSearch1" style="width: 200px; min-width: 80px;">
            </el-input>
            <el-select v-model="search1.status" style="width: 300px" @change="toSearch1">
              <el-option label="全部参考学生" :value="'-1'"/>
              <el-option label="已上传试卷的学生" :value="'1'"/>
              <el-option label="未上传、上传未处理、识别处理错误的学生" :value="'2'"/>
            </el-select>
            <el-button siz="default" @click="toSearch1" icon="Search" text bg>查询</el-button>
            <el-button type="primary" siz="default" @click="showAdd" icon="Plus" text bg> 参考学生</el-button>
            
           <el-button type="primary" @click="studentIdNumberDialogVisible = true" bg text>
             <template #icon><lock theme="multi-color" size="24" /></template>
             自定义信息导入
            </el-button>

            <el-button bg
                           text
                           type="primary"
                           v-show="userStore.user.type != 4"
                           @click="menuClick('exam-examroommge')">
                  <template #icon>
                    <i-door-handle theme="multi-color" size="24" :fill="['#409eff' ,'#a0cfff' ,'#086bfc' ,'#c6e2ff']"/>
                  </template>
                  考场管理
                </el-button>
             <el-button type="danger" siz="default" @click="batchDel(false)"
                       icon="Delete"
                       v-if="selectStudents.length !== 0"
                       text bg
            >
              批量删除
            </el-button>
            <el-button type="danger"
                       @click="batchDel(true)"
                       icon="Delete"
                       v-if="selectStudents.length === 0"
                       text bg
            >
              全部删除
            </el-button>
          </div>
          
          <el-table :data="tableData1" stripe @selection-change="(arr) => selectStudents = arr" style="height: calc(100% - 42px);">
            <el-table-column type="selection" width="55"/>
            <el-table-column prop="school_name" label="学校" min-width="150"></el-table-column>
            <el-table-column prop="student_name" label="姓名" min-width="100"></el-table-column>
            <el-table-column prop="student_number" label="学号" min-width="150"></el-table-column>
            <el-table-column prop="grade" label="年级" min-width="80"></el-table-column>
            <el-table-column prop="class_name" label="班级" min-width="120"></el-table-column>
            <el-table-column label="考号" min-width="150">
              <template v-slot="{row}">
                <div v-for="(info,index) in row.room_info"
                     :key="index"
                >
                  <el-text type="primary">{{subjectIdMap[info.subject_id]}}</el-text>
                  <el-divider direction="vertical"/>
                  <el-text type="info">{{info.student_id}}</el-text>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="考场编排" min-width="300">
              <template v-slot="{row}">
                <div v-for="(info,index) in row.room_info"
                     :key="index"
                >
                  <el-text type="primary">{{subjectIdMap[info.subject_id]}}</el-text>
                  <el-divider direction="vertical"/>
                  <el-text :type="info.room_name ? 'success' : 'warning'">{{info.room_name ? info.room_name : '未编排'}}</el-text>
                  <el-divider direction="vertical"/>
                  <el-text :type="info.seat_num ? 'success' : 'warning'">{{info.seat_num ? info.seat_num : '未编排'}}</el-text>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="试卷上传状态" min-width="320">
              <template v-slot="{row}">
                <div v-for="(info,index) in row.scan_info"
                     :key="index"
                >
                  <el-text type="primary">{{subjectIdMap[info.subject_id]}}</el-text>
                  <el-divider direction="vertical"/>
                  正面：
                  <el-tooltip content="Top center" placement="top" :disabled="!info.page1_err && info.submit != null">
                    <el-tag :type='(info.submit == null ? "warning" : (info.page1_err ? "danger" : (info.submit == 0 ? "info" : "success")))  '
                            @click="showPaper(row, info.subject_id, 1)"
                            :style="info.submit > 0 ? 'cursor: pointer' : ''"
                    >
                      {{(info.submit == null ? '未参考' : (info.page1_err ? "错误！" : (info.submit == 0 ? "未上传" : "已上传")))}}
                    </el-tag>
                    <template #content>
                      {{info.page1_err}}
                      {{info.submit == null ? '未参考/对应关系出错' : ''}}
                    </template>
                  </el-tooltip>
                  
                  <!-- submit=1但是page2_status=0，说明是背面未上传但是已经提交了，说明这个试卷是单面的 -->
                  <span v-if="!(info.submit == 1 && info.page2_status == 0)">
                    <el-divider direction="vertical"/>
                    背面：
                    <el-tooltip content="Top center" placement="top" :disabled="!info.page2_err && info.submit != null">
                      <el-tag :type='(info.submit == null ? "warning" : (info.page2_err ? "danger" : (info.submit == 0 ? "info" : "success")))  '
                              @click="showPaper(row, info.subject_id, 2)"
                              :style="info.submit > 0 ? 'cursor: pointer' : ''"
                      >
                        {{(info.submit == null ? '未参考' : (info.page2_err ? "错误！" : (info.submit == 0 ? "未上传" : "已上传")))}}
                      </el-tag>
                      <template #content>
                        {{info.page2_err}}
                        {{info.submit == null ? '未参考/对应关系出错' : ''}}
                      </template>
                    </el-tooltip>
                  </span>
                  
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="120">
              <template v-slot="scope">
                <el-button @click="toDel(scope.row)" type="danger" size="small">
                  <template #icon>
                    <delete theme="multi-color" size="24" />
                  </template>
                </el-button>
                <el-button @click="toEdit(scope.row)" type="primary" size="small">
                  <template #icon>
                    <edit theme="multi-color" size="24" />  
                  </template>
                </el-button>
              </template>
            </el-table-column>
            </el-table>
          
         
        </el-main>
        <el-footer style="height: 12px;"> 
          <el-pagination
              @current-change="toPage"
              :current-page="search1.page"
              v-model:page-size="search1.pagesize"
              layout="prev, sizes, pager, next, jumper, ->, total"
              :page-sizes="[50,60,70,80,90,100,200,300,400]"
              @change="toPage"
              :total="total1">
          </el-pagination>
        </el-footer>
      </el-container>
    </el-container>

    <el-dialog :title="tableTitle" v-model="dialogVisi" width="950px" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="row">
        <el-input siz="default" placeholder="学生姓名" v-model="search2.stuname" @keyup.enter="toSearch2" style="width: 150px; min-width: 80px;">
        </el-input>
        <el-input siz="default" placeholder="学生学号" v-model="search2.stunum" @keyup.enter="toSearch2" style="width: 150px; min-width: 80px;">
        </el-input>
        <el-select v-model="search2.grade" @keyup.enter="toSearch2" style="width: 150px;">
          <el-option label="全部" :value="'-1'"/>
          <el-option v-for="grade in appStore.exam_grades"
                     :key="grade"
                     :label="grade"
                     :value="grade"
          />
        </el-select>
        <el-input siz="default" placeholder="学生年班级" v-model="search2.class" @keyup.enter="toSearch2" style="width: 150px; min-width: 80px;">
        </el-input>
        <el-button siz="default" @click="toSearch2" icon="Search">查询</el-button>
        <el-button siz="default" type="primary" @click="toSave">保 存</el-button>
      </div>
      <el-table :data="tableData2" stripe @selection-change="handleSelectionChange">
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column prop="student_name" label="姓名" min-width="100"></el-table-column>
        <el-table-column prop="student_number" label="学号" min-width="120"></el-table-column>
        <el-table-column prop="grade" label="年级" min-width="80"></el-table-column>
        <el-table-column prop="class_name" label="班级" min-width="120"></el-table-column>
      </el-table>
      <template #footer>
        <el-button type="primary" @click="toSave">保 存</el-button>
      </template>
    </el-dialog>
    <!--密号对应dialog-->
    <el-dialog
        v-model="studentIdNumberDialogVisible"
        title="自定义信息导入，适用于以下情况："
        width="600"
    >
      <div>
        <el-text type="primary">1、学号与考号不一致</el-text>
      </div>
      <div>
        <el-text type="primary">2、存在考场信息</el-text>
      </div>
      <div>
        <el-text type="primary">3、每个学生参考的科目不同</el-text>
      </div>
      <div>
        <el-text type="danger">注意：导入会删除现在存在的对应关系，请慎重操作！</el-text>
      </div>
      <el-upload
            action="/api/examStudent/importStudentRela"
            :data="search1"
            name="excelfile"
            ref="upload"
            drag
            :multiple="false"
            :before-upload="checkUpload"
            :on-success="afterSuccess">
          <i class="upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能上传xls或xlsx文件，且不超过10MB</div>
        </el-upload>
        <el-button type="primary"
                   size="default"
                   icon="download"
                   @click="downloadImportTemplate"
                   >下载导入模板</el-button>

    </el-dialog>

    <el-dialog title="导入数据出错" v-model="errorVisi" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-table :data="errorData">
        <el-table-column prop="row" label="行号" width="80"></el-table-column>
        <el-table-column prop="msg" label="错误信息" width="600"></el-table-column>
      </el-table>
    </el-dialog>

    <!-- 添加查看试卷的对话框 -->
    <el-dialog v-model="paperDialogVisible" title="查看试卷" width="80%">
      <div v-if="paperImage" style="text-align: center;">
        <img :src="paperImage" style="max-width: 100%;" />
      </div>
      <div v-else>
        <el-empty description="加载中..." />
      </div>
    </el-dialog>

    <el-dialog v-model="editDialogVisible"  width="600px">
      <template #title>
        {{editRowData.school_name}} - {{editRowData.student_name}} - 
        {{editRowData.student_number}} -
        {{editRowData.grade}} - {{editRowData.class_name}}班
      </template>
      <el-checkbox-group v-model="editForm.selectedSubjects" @change="calcEditTableData">
        <el-checkbox v-for="(subject,index) in appStore.exam_subjects_name" :key="appStore.exam_subject_ids[index]" :label="appStore.exam_subject_ids[index]">
          {{subject}}
        </el-checkbox>
      </el-checkbox-group>
      
      <el-table :data="editTableData">
        <el-table-column prop="subject_name" label="科目" width="120"></el-table-column>
        <el-table-column prop="student_id" label="考号" width="120">
          <template v-slot="{row}">
            <el-input v-model="row.student_id"></el-input>  
          </template>
        </el-table-column>
        <el-table-column prop="room_name" label="考场" width="120">
          <template v-slot="{row}">
            <el-input v-model="row.room_name"></el-input>
          </template>  
        </el-table-column>
        <el-table-column prop="seat_num" label="座位号" width="120">
          <template v-slot="{row}">
            <el-input v-model="row.seat_num"></el-input>
          </template>
        </el-table-column>
      </el-table>
      
      <template #footer>
        <el-button @click="editDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveEdit">保存</el-button>  
      </template>
    </el-dialog>

  </el-container>
</template>

<script>
import {useAppStoreWithOut} from "@/store/modules/app";
import {useTagStoreWithOut} from "@/store/modules/tag";
import {useUserStoreWithOut} from "@/store/modules/user";

export default {
  name: "ManageExamStudent",
  data () {
    return{
      loading: false,
      exam: {
        exam_id: null,
        exam_name: ""
      },
      tableData1:[],
      tableData2:[],
      multipleSelection:[],
      dialogVisi: false,
      tableTitle: "未参考学生列表",
      total1: 0,
      total2: 0,
      search1: {
        page: 1,
        pagesize: 50,
        examid: null,
        stuname: "",
        stunum: "",
        status:"-1"
      },
      search2: {
        examid: null,
        stuname: "",
        stunum: "",
        grade:"-1",
        class:""
      },
      studentIdNumberDialogVisible: false,
      relationType: '与学号一致',
      errorVisi: false,
      errorData:[],


      subjects:[], // 科目

      selectStudents:[], // 复选框选择的学生

      // 添加这两个新的数据属性
      paperDialogVisible: false,  // 控制试卷预览对话框的显示
      paperImage: null,          // 存储试卷图片数据

      editDialogVisible: false,
      editForm: {
        exam_id: null,
        student_number: '',  
        selectedSubjects: [],
      },
      editRowData: null, // 编辑行数据
      editTableData: [],
    }
  },
  setup(){
    const appStore = useAppStoreWithOut()
    const tagStore = useTagStoreWithOut()
    const userStore = useUserStoreWithOut()
    return {appStore,tagStore,userStore}
  },
  computed:{
    subjectIdMap(){
      const res = {}
      this.subjects.forEach(item => {
        res[item.tag_id] = item.tag_name
      })
      return res
    }
  },
  mounted() {
    this.tagStore.getTag("subject").then((subjects) => {
      this.subjects = subjects
    })
    this.exam.exam_id = this.search1.examid  = this.search2.examid = this.appStore.exam_id
    this.exam.exam_name = this.appStore.exam_name
    this.loadStuInclude()
    this.loadStuExclusive()
  },
  methods: {
    // 学号和密号一样的
    generateStudentIdWithNumber(){
      this.axios.post("/api/examStudent/importStudentRela", {...this.search1,consistentWithStudentNumber:"1"},{
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
          .then((response) => {
            if (response.data.success) {
              this.$message.success(response.data.result)
              this.studentIdNumberDialogVisible = false
            }
          })
    },
    //上传前检查
    checkUpload (file) {
      let name = file.name.toLowerCase()
      if (!name.endsWith(".xls") && !name.endsWith(".xlsx")) {
        this.$message({message: "只能上传xls或xlsx文件", type: "warning"})
        return false
      }
      this.loading = true
      return true
    },
    //上传后处理
    afterSuccess (data) {
      this.loading = false
      this.$refs["upload"].clearFiles()
      if (data.success) {
        this.$message({message: "导入成功", type: "success"})
        this.studentIdNumberDialogVisible = false
        this.loadStuInclude()
      }
      else {
        if (typeof data.result === "string")
          this.$alert(data.result, "导入出错")
        else {
          this.errorData = data.result
          this.errorVisi = true
        }
      }
    },
    //下载导入模板
    downloadImportTemplate(){
      this.axios({
        method: "post",
        url: "/api/examStudent/downImportTemplate",
        data: this.search1,
        responseType: 'blob'
      }).then(res => {
        const link = document.createElement('a')
        let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob);
        const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
        link.setAttribute('download', decodeURIComponent(fileName))
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }, err => {
        console.log(err)
        this.$alert(err, "出错了")
      });
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //载入参考学生列表
    loadStuInclude () {
      this.axios.post("/api/examStudent/loadstuinclude", this.search1)
          .then((response) => {
            if (response.data.success) {
              this.total1 = response.data.result.totalrecords
              this.tableData1 = response.data.result.datalist
            }
          })
    },

    //新增
    showAdd () {
      if (this.search2.examid !== null) {
        this.dialogVisi = true
        this.loadStuExclusive()
        this.multipleSelection = []
      }
      else
        this.$message({message: "考试ID缺失", type: "warning"})
    },

    //载入未参考学生列表
    loadStuExclusive () {
      const grade = this.search2.grade === "-1" ? "" : this.search2.grade
      this.axios.post("/api/examStudent/loadstuexclusive", {...this.search2,grade})
          .then((response) => {
            if (response.data.success) {
              /*this.total2 = response.data.totalrecords*/
              this.tableData2 = response.data.result
            }
          })
    },

    //页码跳转
    toPage (page) {
      this.search1.page = page
      this.loadStuInclude()
    },

    toSearch1 () {
      this.search1.page = 1
      this.loadStuInclude()
    },

    toSearch2 () {
      this.loadStuExclusive()
    },

    //删除
    toDel (data) {
      this.$confirm("确认删除此学生?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/examStudent/delstu", { student_number: [data.student_number], examid : this.search1.examid })
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "删除成功", type: "success"})
                this.loadStuInclude()
              }
            })
      }).catch(()=>{})
    },
    // 批量删除
    batchDel(delAll){
      const msg = delAll ? '确认删除所有的学生？该操作不可撤回' : '确认删除所选择的学生？'
      this.$confirm(msg, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        const stus = this.selectStudents.map(s => s.student_number)

        const data =  {examid : this.search1.examid }
        if (!delAll){
          data.student_number = stus
        }

        this.axios.post("/api/examStudent/delstu", data)
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "删除成功", type: "success"})
                this.loadStuInclude()
              }
            })
      }).catch(()=>{})
    },
    //保存
    toSave () {
        if (this.multipleSelection.length !== 0) {
          this.axios.post("/api/examStudent/savestu", { examid :this.search2.examid, stuList: this.multipleSelection})
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "保存成功",type: "success"})
                  this.loadStuInclude()
                  this.loadStuExclusive()
                }
              })

        }
        else
          this.$message({message: "请勾选学生！", type: "warning"})
    },

    // 显示试卷图片
    showPaper(student, subjectId, page) {
      // 只有已上传的试卷才能查看
      const scanInfo = student.scan_info.find(info => info.subject_id === subjectId)
      if (!scanInfo || scanInfo.submit <= 0) {
        return
      }

      this.paperDialogVisible = true
      this.paperImage = null
      
      this.axios.post("/api/examStudent/getStudentPaper", {
        exam_id: this.exam.exam_id,
        subject_id: subjectId,
        student_id: scanInfo.student_id,
        page: page
      }).then(response => {
        if (response.data.success) {
          this.paperImage = response.data.result.startsWith('data:image') 
            ? response.data.result 
            : 'data:image/jpeg;base64,' + response.data.result
        } else {
          this.$message.error(response.data.result || "获取试卷图片失败")
        }
      }).catch(error => {
        console.error(error)
        this.$message.error("获取试卷图片失败")
      })
    },

    menuClick(name){
      // 路由前进为url
      this.$router.push({name})
    },

    toEdit(row) {
      this.editForm.exam_id = this.exam.exam_id
      this.editForm.student_number = row.student_number
      this.editForm.selectedSubjects = []
      
      this.axios.post("/api/examStudent/getStudentInfo", {
        exam_id: this.exam.exam_id, 
        student_number: row.student_number
      }).then(response => {
        if (response.data.success) {
          const data = response.data.result
          this.editRowData = data
          this.editForm.selectedSubjects = data.scan_info
            .filter(item => item.submit)
            .map(item => item.subject_id)
          this.calcEditTableData()
          this.editDialogVisible = true  
        }
      })
    },

    calcEditTableData() {
      this.editTableData = this.appStore.exam_subject_ids
        .filter(subjectId => this.editForm.selectedSubjects.includes(subjectId))
        .map(subjectId => {
          const scanInfo = this.editRowData.scan_info.find(item => item.subject_id === subjectId)
          const roomInfo = this.editRowData.room_info.find(item => item.subject_id === subjectId)
          const subjectNameIndex = this.appStore.exam_subject_ids.indexOf(subjectId)
          return {
            subject_id: subjectId,
            subject_name: this.appStore.exam_subjects_name[subjectNameIndex],
            student_id: scanInfo ? scanInfo.student_id : '',
            room_name: roomInfo ? roomInfo.room_name : '',
            seat_num: roomInfo ? roomInfo.seat_num : '',
          }
        })
    },

    saveEdit() {
      const updateData = this.editTableData.map(item => ({
        subject_id: item.subject_id,
        student_id: item.student_id,
        room_name: item.room_name ? item.room_name : null,
        seat_num: item.seat_num ? item.seat_num : null,
      }))
      
      this.axios.post("/api/examStudent/updateStudentInfo", {
        exam_id: this.editForm.exam_id,
        student_number: this.editForm.student_number,
        update_data: updateData,
      }).then(response => {
        if (response.data.success) {
          this.$message.success("修改成功")
          this.editDialogVisible = false
          this.loadStuInclude()
        } else {
          this.$message.error(response.data.result)  
        }
      })
    },
  },
}
</script>

<style scoped>
/* 添加鼠标悬停样式 */
.el-tag[style*="cursor: pointer"]:hover {
  opacity: 0.8;
}
</style>
