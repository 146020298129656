<script>
export default {
    name: 'ExamTimeManage',
    props: {
        examId: {
            type: Number,
            default: null,
            required: true
        }
    },
    data() {
        return {
            examTimeData: [], // 考试时间列表
            editExamTimeDialog: false, // 编辑对话框显示状态
            editExamTimeData: {
                subject_name: '',
                exam_start_time: '',
                exam_end_time: ''
            },
        }
    },

    methods: {
        // 编辑考试时间
        editExamTime(row) {
            this.editExamTimeData = { ...row }
            this.editExamTimeDialog = true
        },

        // 保存考试时间
        async saveExamTime() {
            if (!this.validateExamTime()) {
                return
            }
            this.axios.post("/api/examroomdesign/saveexamtime", this.editExamTimeData).then(
                res => {
                    if (res.data.success) {
                        this.$message.success("保存成功")
                        this.editExamTimeDialog = false
                        this.initExamTimeData()
                    }
                }
            )
        },

        // 验证考试时间
        validateExamTime() {
            if (!this.editExamTimeData.exam_start_time || !this.editExamTimeData.exam_end_time) {
                this.$message.warning('请选择考试时间')
                return false
            }
            if (this.editExamTimeData.exam_start_time >= this.editExamTimeData.exam_end_time) {
                this.$message.warning('开始时间必须早于结束时间')
                return false
            }
            return true
        },


        // 获取考试时间列表
        async initExamTimeData() {
            this.axios.post("/api/examroomdesign/getexamtime", { exam_id: this.examId }).then(
                res => {
                    if (res.data.success) {
                        this.examTimeData = res.data.result
                        this.$emit('updata', this.examTimeData)
                    }
                }
            )
        }
    },

    created() {
        this.initExamTimeData()
    }
}
</script>
<template>
    <div style="margin: 20px auto">
        <el-alert style="margin: 12px 0" show-icon description="温馨提示：若科目间考试时重叠，建议配置所有科目考试时间后在进行编排，系统将据考试时间提示考场占用情况"
            type="info"></el-alert>
        <el-table :data="examTimeData" border>
            <el-table-column prop="subject_name" label="科目" align="center" />
            <el-table-column prop="exam_start_time" label="考试开始时间" align="center" />
            <el-table-column prop="exam_end_time" label="考试结束时间" align="center" />
            <el-table-column label="操作" align="center">
                <template #default="scope">
                    <el-button type="info" @click="editExamTime(scope.row)">
                        <i-editor style="margin-right: 5px" theme="filled" size="14" :strokeWidth="2" />
                        编辑
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog v-model="editExamTimeDialog" title="设置考试时间" width="40%">
            <el-form :model="editExamTimeData" label-width="120px">
                <el-form-item label="科目">
                    <el-input v-model="editExamTimeData.subject_name" disabled></el-input>
                </el-form-item>
                <el-form-item label="考试开始时间" required>
                    <el-date-picker type="datetime" format="YYYY-MM-DD HH:mm:ss"
                        v-model="editExamTimeData.exam_start_time" placeholder="选择日期时"></el-date-picker>
                </el-form-item>
                <el-form-item label="考试结束时间" required>
                    <el-date-picker type="datetime" format="YYYY-MM-DD HH:mm:ss"
                        v-model="editExamTimeData.exam_end_time" placeholder="选择日期时间"></el-date-picker>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="editExamTimeDialog = false" type="info">
                    取消
                </el-button>
                <el-button @click="saveExamTime" type="success">
                    确定
                </el-button>
            </template>
        </el-dialog>
    </div>
</template>

<style scoped></style>
