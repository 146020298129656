<script>

import {useUserStoreWithOut} from "@/store/modules/user";
import {useAppStoreWithOut} from "@/store/modules/app";

export default {
  name: "ExamRoomMge",
  setup(){
    const appStore = useAppStoreWithOut()
    const userStore = useUserStoreWithOut()
    return {appStore,userStore}
  },
  data(){
    return{
      filterName: "",
      treeData: [],
      treeProps: {
        children: 'subunit',
        label: 'unit_name'
      },
      selectUnit: {},
      showTable: 1,

      examTreeData: [],
      selectTreeCascade: [],
      search1:{
        exam_id: null,
        subject_id: null,
        unit_id:null,
        page: 1,
        pagesize: 15,
      },
      tableData1:[],
      total1:0,


      roomFormTitle: "",
      roomDialogVisi: false,
      roomForm:{},
      roomFormRules: {
        name: [{required: true, message: "请填写考场名称", trigger: "blur"}, {max: 30, message: "不能超过30个字", trigger: "blur"}],
        subject_id:[{required: true, message: "请选择科目", trigger: "blur"}],
      },


      clickRoom:{},

      search2:{
        page: 1,
        pagesize: 15,
        uploadFail: false
      },
      tableData2:[],
      total2:0,

      roomStudentFormTitle: "",
      roomStudentDialogVisi: false,
      roomStudentForm:{},
      roomStudentFormRules: {
        student_id: [{required: true, message: "请填写密号", trigger: "blur"}],
        seat_num: [{required: true, message: "请填写座位号", trigger: "blur"}],
      },

      uploadVisi: false,
      uploadData:{
        unitid: null
      },
      errorVisi: false,
      errorData: [],
    }
  },
  methods:{
    //显示上传框
    showUpload () {
      if (this.search1.unit_id != null) {
        this.uploadVisi = true
        this.$refs["upload"].clearFiles()
      }
      else
        this.$message({message: "请先选择单位", type: "warning"})
    },
    //关闭上传框
    closeUpload () {
      this.uploadVisi = false
    },
    //上传前检查
    checkUpload (file) {
      if (this.search1.unit_id == null) {
        this.$message({message: "请先选择单位", type: "warning"})
        return false
      }
      let name = file.name.toLowerCase()
      if (!name.endsWith(".xls") && !name.endsWith(".xlsx")) {
        this.$message({message: "只能上传xls或xlsx文件", type: "warning"})
        return false
      }
      return true
    },
    //上传后处理
    afterSuccess (data) {
      this.$refs["upload"].clearFiles()
      if (data.success) {
        this.$message({message: "导入成功", type: "success"})
        this.listExamRoomInfo()
        this.uploadVisi = false
      }
      else {
        if (typeof data.result === "string")
          this.$alert(data.result, "导入出错")
        else {
          this.errorData = data.result
          this.errorVisi = true
        }
      }
    },
    // 下载导入模板
    downloadImportTemplate(){
      this.axios({
        method: "post",
        url: "/api/examroom/downImportTemplate",
        data: this.search1,
        responseType: 'blob'
      }).then(res => {
        const link = document.createElement('a')
        let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob);
        const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
        link.setAttribute('download', decodeURIComponent(fileName))
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }, err => {
        console.log(err)
        this.$alert(err, "出错了")
      });
      },
    // 保存学生
    toSaveRoomStudent(){
      this.$refs["roomStudentForm"].validate((valid) => {
        if (valid) {
          let url = ""
          if (this.roomStudentFormTitle.indexOf("编辑") !== -1)
            url = "/api/examroom/updateExamRoomStudent"
          else
            url = "/api/examroom/saveExamRoomStudent"
          this.axios.post(url, {
            ...this.search1,
            ...this.search2,
            room_id: this.clickRoom.room_id,
            ...this.roomStudentForm,
            student_id: this.roomStudentForm.student_id + "",
            seat_num: parseInt(this.roomStudentForm.seat_num),
            subject_id: this.clickRoom.subject_id
          })
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "保存成功", type: "success"})
                  this.roomStudentDialogVisi = false
                  this.roomStudentForm = {}
                  this.listRoomStudent()
                }
              })
        }
        else
          return false
      })
    },
    // 获取某个考场的学生列表
    listRoomStudent(){
      this.axios.post("/api/examroom/listExamRoomStudent", {
        ...this.search1,
        ...this.search2,
        room_id: this.clickRoom.room_id})
          .then((response) => {
            if (response.data.success) {
              this.total2 = response.data.result.totalrecords
              this.tableData2 = response.data.result.datalist
              this.showTable = 2
            }
          }
      )
    },

    // 点击考场
    roomClick(row){
      this.clickRoom = row
      this.showTable = 2
      this.listRoomStudent()
    },

    // 删除考场
    toDelRoom(row){
      this.$confirm("确认删除考场吗？该考场下的所有学生密号座位号一并会被删除", "提示", {type: "warning"})
          .then(() => {
            this.axios.post("/api/examroom/deleteExamRoom", {room_id: row.room_id})
                .then((response) => {
                  if (response.data.success) {
                    this.$message({message: "删除成功", type: "success"})
                    this.listExamRoomInfo()
                  }
                })
          })
    },
    // 删除考场学生
    toDelRoomStudent(row){
      this.$confirm("确认删除考生吗？", "提示", {type: "warning"})
          .then(() => {
            this.axios.post("/api/examroom/deleteExamRoomStudent", {
              unit_id: this.search1.unit_id,
              room_id: this.clickRoom.room_id,
              seat_num: row.seat_num,
            })
                .then((response) => {
                  if (response.data.success) {
                    this.$message({message: "删除成功", type: "success"})
                    this.listRoomStudent()
                  }
                })
          })
    },
    // 打开编辑考场
    showEditRoom(row){
      this.roomForm = {...row}
      this.roomFormTitle = "编辑考场"
      this.roomDialogVisi = true
    },
    // 打开编辑考场学生
    showEditRoomStudent(row){
      this.roomStudentForm = {...row}
      this.roomStudentFormTitle = "编辑考生"
      this.roomStudentDialogVisi = true
    },
    // 新增考场
    toSaveRoom(){
      this.$refs["roomForm"].validate((valid) => {
        if (valid) {
          let url = ""
          if (this.roomForm.room_id)
            url = "/api/examroom/updateExamRoom"
          else
            url = "/api/examroom/saveExamRoom"

          this.axios.post(url, {...this.search1,...this.roomForm})
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "考场保存成功", type: "success"})
                  this.roomDialogVisi = false
                  this.listExamRoomInfo()
                }
              })
        }
        else
          return false
      })
    },
    // 打开新增考场
    showAddRoom(){
      this.roomFormTitle = "新增考场"
      this.roomDialogVisi = true
    },
    // 打开新增考场学生
    showAddRoomStudent(){
      this.roomStudentFormTitle = "新增考生"
      this.roomStudentDialogVisi = true
    },
    //载入考场列表
    listExamRoomInfo () {
      this.axios.post("/api/examroom/listExamRoomInfo", this.search1)
          .then((response) => {
            if (response.data.success) {
              this.total1 = response.data.result.totalrecords
              this.tableData1 = response.data.result.datalist
            }
          })
    },
    //页码跳转
    toPage (page) {
      this.search1.page = page
      this.listExamRoomInfo()
    },
    //页码跳转
    toPage2 (page) {
      this.search2.page = page
      this.listRoomStudent()
    },
    //条件查询
    toSearch1 () {
      this.search1.page = 1
      this.listExamRoomInfo()
    },
    //条件查询
    toSearch2 () {
      this.search2.page = 1
      this.listRoomStudent()
    },
    menuClick(name){
      // 路由前进为url
      this.$router.push({name})
    },

  },
  mounted() {
    this.search1.unit_id = this.appStore.exam_unit_id
    this.search1.exam_id = this.appStore.exam_id
    this.search1.subject_id = this.appStore.subject_id
    this.listExamRoomInfo()
  }
}
</script>

<template>
  <el-container class="base-container">
    <el-container class="main-container">
      <el-main style="border-left: 1px solid #EBEEF5;">

        <el-space wrap style="margin-bottom: 10px">
          <el-button
              bg text
              icon="ArrowLeft"
              @click="menuClick('exam-mgeexamstudent')"
          >
            返回参考学生管理
          </el-button>
          <el-button icon="Search" @click="() => {
            if(showTable === 1)
              toSearch1()
            else
              toSearch2()
          }" v-show="search1.unit_id !== null" bg text>查询</el-button>
           <el-button type="primary"
                           text
                           bg
                           @click="menuClick('exam-examroomdesign')"
                           v-show="userStore.user.type == 1 || userStore.user.type == 2 || userStore.user.type == 6"
                >
                  <template #icon>
                    <i-association theme="multi-color" size="24" :fill="['#409eff' ,'#a0cfff' ,'#086bfc' ,'#c6e2ff']"/>
                  </template>
                  考场编排
              </el-button>
          <el-button type="primary"
                     size="default"
                     bg text
                     @click="showAddRoom"
                     icon="Plus"
                     v-if="search1.unit_id !== null && showTable === 1"
          >
            考场
          </el-button>
          <el-button type="primary"
                     size="default"
                     bg text
                     @click="showAddRoomStudent"
                     icon="Plus"
                     v-if="search1.unit_id !== null && showTable === 2"
          >
            考生
          </el-button>
          <!-- <el-popover
              trigger="click"
              ref="popoverUpload"
              placement="bottom"
              :visible="uploadVisi"
              width="360px">
            <template #reference>
              <el-button type="success" siz="default" bg text icon="upload" @click="showUpload">导入</el-button>
            </template>
            <div style="width: 100%; text-align: right;"><el-button link icon="Close" @click="closeUpload"></el-button></div>
            <el-upload
                action="/api/examroom/importRoomStudent"
                :data="{
                  exam_id: search1.exam_id,
                  subject_id: search1.subject_id
                }"
                name="excelfile"
                ref="upload"
                drag
                :multiple="false"
                :before-upload="checkUpload"
                :on-success="afterSuccess">
              <i class="upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">只能上传xls或xlsx文件，且不超过10MB</div>
            </el-upload>
          </el-popover>
          <el-button type="primary"
                     size="default"
                     bg text
                     icon="download"
                     @click="downloadImportTemplate"
                     v-if="search1.unit_id !== null && showTable === 1">下载导入模板</el-button> -->

        </el-space>
        <el-space style="width: 100%">
          <el-button
              bg text
              icon="ArrowLeft"
              @click="() => {
                showTable = 1
                tableData2 = []
                total2 = 0
              }"
              v-show="search1.unit_id !== null && showTable === 2"
          >
            返回
          </el-button>
        </el-space>
        <el-table :data="tableData1" stripe v-if="showTable === 1">
          <el-table-column prop="subject_name" label="科目" min-width="100"></el-table-column>
          <el-table-column prop="name" label="考场名" min-width="100">
            <template v-slot="scope">
              <el-link type="primary" @click="roomClick(scope.row)">{{scope.row.name}}</el-link>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" min-width="120"></el-table-column>
          <el-table-column prop="last_edit_time" label="上次修改时间" min-width="120"></el-table-column>
          <el-table-column prop="last_edit_user_name" label="上次修改人" min-width="100"></el-table-column>
          <el-table-column label="操作" fixed="right" width="250">
            <template v-slot="scope">
              <el-button class="table-button" @click="showEditRoom(scope.row)">编辑</el-button>
              <el-button class="table-button" @click="toDelRoom(scope.row)" type="danger">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            v-if="showTable === 1"
            @current-change="toPage"
            :current-page="search1.page"
            :page-size="search1.pagesize"
            layout="total, prev, pager, next, jumper"
            :total="total1">
        </el-pagination>

        <el-table :data="tableData2" stripe v-if="showTable === 2">
          <el-table-column prop="room_name" label="考场名" min-width="100">
          </el-table-column>
          <el-table-column prop="student_id" label="考号" min-width="120"></el-table-column>
          <el-table-column prop="seat_num" label="座位号" min-width="120"></el-table-column>
          <el-table-column prop="last_edit_time" label="上次修改时间" min-width="120"></el-table-column>
          <el-table-column prop="last_edit_user_name" label="上次修改人" min-width="100"></el-table-column>
          <el-table-column label="操作" fixed="right" width="150">
            <template v-slot="scope">
              <el-button class="table-button" @click="showEditRoomStudent(scope.row)">编辑</el-button>
              <el-button class="table-button" @click="toDelRoomStudent(scope.row)" type="danger">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            v-if="showTable === 2"
            @current-change="toPage2"
            :current-page="search2.page"
            :page-size="search2.pagesize"
            layout="total, prev, pager, next, jumper"
            :total="total2">
        </el-pagination>
      </el-main>
    </el-container>

    <el-dialog :title="roomFormTitle" v-model="roomDialogVisi" width="650px" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form :model="roomForm" :rules="roomFormRules" ref="roomForm" label-width="100px">
        <el-form-item label="科目" prop="subject_id" >
          <el-select v-model="roomForm.subject_id">
            <el-option v-for="(item,index) in appStore.exam_subjects_name" :key="index"
                       :label="item"
                       :value="appStore.exam_subject_ids[index]"/>
          </el-select>
        </el-form-item>
        <el-form-item label="考场名" prop="name" >
          <el-input v-model="roomForm.name"/>
        </el-form-item>
        <el-form-item label="备注" prop="remark" >
          <el-input v-model="roomForm.remark" type="textarea"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="()=>{
          roomDialogVisi = false
          roomForm = {}
        }">取 消</el-button>
        <el-button type="primary" @click="toSaveRoom">保 存</el-button>
      </template>
    </el-dialog>


    <el-dialog :title="roomStudentFormTitle" v-model="roomStudentDialogVisi" width="650px" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form :model="roomStudentForm" :rules="roomStudentFormRules" ref="roomStudentForm" label-width="100px">
        <el-form-item label="科目" prop="subject_name" >
          {{clickRoom.subject_name}}
        </el-form-item>
        <el-form-item label="考场名" prop="name" >
          {{clickRoom.name}}
        </el-form-item>
        <el-form-item label="座位号" prop="seat_num" >
          <el-input v-model="roomStudentForm.seat_num" :disabled="roomStudentFormTitle.indexOf('编辑') !== -1"/>
        </el-form-item>
        <el-form-item label="密号" prop="student_id" >
          <el-input v-model="roomStudentForm.student_id" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="()=>{
          roomStudentDialogVisi = false
          roomStudentForm = {}
        }">取 消</el-button>
        <el-button type="primary" @click="toSaveRoomStudent">保 存</el-button>
      </template>
    </el-dialog>
    <el-dialog title="导入数据出错" v-model="errorVisi" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-table :data="errorData">
        <el-table-column prop="row" label="行号" width="80"></el-table-column>
        <el-table-column prop="msg" label="错误信息" width="600"></el-table-column>
      </el-table>
    </el-dialog>
  </el-container>
</template>

<style scoped>

</style>