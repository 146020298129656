<template>
  <div class="status-dot">
    <span class="dot" :class="dotClass"></span>
    <span class="text"><slot></slot></span>
  </div>
</template>

<script>
export default {
  name: 'StatusDot',
  props: {
    type: {
      type: String,
      default: 'primary',
      validator: (value) => {
        return ['primary', 'warning', 'info', 'success', 'danger'].includes(value)
      }
    }
  },
  computed: {
    dotClass() {
      return `dot-${this.type}`
    }
  }
}
</script>

<style scoped>
.status-dot {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  min-width: fit-content;
}

.status-dot .dot {
  min-width: 8px;
  min-height: 8px;
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  flex-shrink: 0;
}

.status-dot .dot.dot-primary {
  background: var(--el-color-primary);
}

.status-dot .dot.dot-warning {
  background: var(--el-color-warning);
}

.status-dot .dot.dot-info {
  background: var(--el-color-info);
}

.status-dot .dot.dot-success {
  background: var(--el-color-success);
}

.status-dot .dot.dot-danger {
  background:var(--el-color-danger);
}

.status-dot .text {
  color: #1b1919;
  flex: 1;
}
</style>
