<!--
客观题答案配置界面
最后编辑人：李正华
最后编辑时间：2022-4-19
最后编辑细节：完善界面
-->
<template>
  <el-container class="base-container">
    <el-container class="main-container" ref="mainSector">
      <el-container style="height: calc(100% - 32px);">
        <el-main>
          <div class="fixed-buttons">
            <el-space>
              <el-button type="warning" @click="downloadImportTemplate" icon="download">下载</el-button>
              <el-popover
                trigger="click"
                ref="popoverUpload"
                placement="bottom"
                :visible="uploadVisi"
                width="360px">
                <template #reference>
                  <el-button type="success" size="default" icon="upload" @click="showUpload">导入</el-button>
                </template>
                <div style="width: 100%; text-align: right;">
                  <el-button link icon="Close" @click="closeUpload"></el-button>
                </div>
                <el-upload
                  action="/api/exam/subquestionanswer/importObjectiveAnswer"
                  :data="{
                    examid: appStore.exam_id,
                    subjectid: appStore.subject_id
                  }"
                  name="excelfile"
                  ref="upload"
                  drag
                  :multiple="false"
                  :before-upload="checkUpload"
                  :on-success="afterSuccess">
                  <i class="upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip">只能上传xls或xlsx文件，且不超过10MB</div>
                </el-upload>
              </el-popover>
            </el-space>
          </div>

          <div style="height: calc(100% - 110px); overflow: auto; border: solid 1px #EBEEF5;">
            <div v-for="ques in questionList" :key="ques.id" class="question-card">
              <div class="objective-name">
                <i class="document"></i>
                <span style="padding: 0 5px;">{{ ques.name }}</span>
                <span style="padding: 0 5px;">{{ "总分：" + ques.maxscore }}</span>
              </div>
              <ul style="list-style-type: none; width: calc(100% - 50px); padding-inline-start: 20px;">
                <li v-for="subques in ques.sub_list" :key="subques.id" class="subquestion-card">
                  <div class="objective-name">
                    <i class="document"></i>
                    <span style="padding: 0 5px;">{{ subques.name }}</span>
                    <span style="padding: 0 5px;" >{{ "满分：" }}
                      <el-input-number :min="0"
                                       v-model="subques.fullScore"
                                       :disabled="!(subques.ismulti && subques.type == 2)"
                                       :controls="false"
                                       style="width: 50px"
                                       size="small"
                      ></el-input-number>
                    </span>
                    <el-tag type="success" size="default" v-if="subques.ismulti">多选</el-tag>
                    <el-tag type="danger" size="default" v-if="subques.answers == null">未配置</el-tag>
                  </div>
                  <div class="objective-answer">
                    <el-row type="flex" justify="space-between">
                      <el-col :span="10" v-if="subques.ismulti">
                        <el-radio-group v-model="subques.type" @change="gradeTypeChange(subques)">
                          <el-radio label="1">匹配得分</el-radio>
                          <el-radio label="2">按权累加</el-radio>
                        </el-radio-group>
                      </el-col>
                      <el-col :span="8" v-if="subques.ismulti && subques.type === '2'" >
                        最多选项
                        <el-input-number
                          v-model="subques.maxChoiceCount"
                          :min="1"
                          style="width: 80px"
                          size="small"
                          />
                      </el-col>
                      <el-col :span="4" v-if="subques.type !== '2'" style="text-align: right; padding-right: 5px;">
                        <el-button size="default" icon="Plus" round @click="toAnswerAdd(subques)"
                                   :disabled="subques.ismulti && subques.type === '1' && subques.answerList.length >= 1"
                        >答案</el-button>
                      </el-col>
                    </el-row>
                    <el-row v-if="(subques.ismulti && subques.type == 2)"
                            style="padding: 3px 0; border-top: solid 1px #F2F6FC; border-bottom: solid 1px #F2F6FC;">
                      <el-col :span="8">
                      </el-col>
                      <el-col :span="6">
                        <span style="color:red;">满分：</span>
                        <el-input-number :min="0"
                                         v-model="subques.fullScore"
                                         style="width: 80px"
                                         size="small"
                        ></el-input-number>
                      </el-col>
                    </el-row>
                    <el-row v-for="(ans,aidx) in subques.answerList" :key="aidx"
                            style="padding: 3px 0; border-top: solid 1px #F2F6FC; border-bottom: solid 1px #F2F6FC;">
                      <el-col :span="1">
                        <el-button type="danger" icon="Delete" size="small" v-show="aidx > 0 && subques.type != 2" @click="() => {
                          // 删除这条答案
                          subques.answerList.splice(aidx, 1)
                        }"/>
                      </el-col>
                      <el-col :span="4">
                        <el-tag v-for="opt in ans.options" :key="opt.idx" effect="dark"
                                size="default" :type="opt.checked ? 'success' : 'info'"
                                style="margin-left: 5px; cursor: pointer;"
                                @click="optionTagClick(opt, ans, subques)">{{ opt.char }}</el-tag>
                      </el-col>
                      <el-col :span="3" style="padding: 0 5px;">
                        <span style="padding-right: 5px">{{ ans.checkStr }}</span>
                      </el-col>
                      <el-col :span="6">
                        <span style="color:green;">得分：</span>
                        <el-input-number size="small" :min="0" v-model="ans.score" style="width: 80px;"
                                         @change="(val) => {
                                           reCalcSubquestionMaxScore(subques)
                                           if(subques.ismulti && subques.type === '1'){
                                             ans.insufficientScore = parseInt(ans.score / 2)
                                           }
                                         }">
                        </el-input-number>
                      </el-col>
                      <el-col :span="8" v-show="subques.ismulti && subques.type === '1'">
                        <span style="color:red;">不全对得分：</span>
                        <el-input-number size="small" :min="0" v-model="ans.insufficientScore" style="width: 80px;"
                                         @change="() => {reCalcSubquestionMaxScore(subques)}">
                        </el-input-number>
                      </el-col>
                      <el-col :span="2"  style="text-align: right; padding-right: 10px;">
                        <el-button type="danger" size="default" icon="delete" circle
                                   v-if="subques.ismulti && subques.answerList.length > 1 && subques.type !== '2'"
                                   @click="toAnswerDel(ans, subques)"></el-button>
                      </el-col>
                    </el-row>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <el-row>
            <el-col :span="24" style="margin-top: 8px; text-align: center;">
              <el-button type="primary" @click="toAnswerSave">保 存</el-button>
            </el-col>
          </el-row>
        </el-main>
      </el-container>
    </el-container>

    <el-dialog title="导入数据出错" v-model="errorVisi" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-table :data="errorData">
        <el-table-column prop="row" label="行号" width="80"></el-table-column>
        <el-table-column prop="msg" label="错误信息" width="600"></el-table-column>
      </el-table>
    </el-dialog>
  </el-container>
</template>

<script>
import {useAppStoreWithOut} from "@/store/modules/app";

export default {
  name: "SubquestionAnswer",
  data () {
    return {
      exam: {
        exam_id: null,
        exam_name: "",
        unit_id: null,
        unit_name: "",
        exam_state: "",
        subjects: []
      },
      search: {
        examid: null,
        subjectid: null
      },
      questionList: [],
      questionTreeProps: {
        children: 'sub_list',
        label: 'name'
      },
      alphabet: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      uploadVisi: false,
      errorVisi: false,
      errorData: [],
    }
  },
  setup(){
    const appStore = useAppStoreWithOut()
    return {appStore}
  },
  mounted() {
    this.examID = this.appStore.exam_id
    if (this.examID != null) {
      this.examID = Number(this.examID)
      this.search.examid = this.examID
    }
    this.search.subjectid = this.appStore.subject_id
    this.loadSubjectSubquestion()
  },
  methods: {
    //载入子题列表
    loadSubjectSubquestion () {
      this.axios.post("/api/exam/subquestionanswer/loadsubquestionforanswer", this.search)
          .then((response) => {
            if (response.data.success) {
              const queslist = response.data.result
              queslist.forEach(ques => {
                ques.sub_list.forEach(subques => {
                  this.buildAnswerList(subques)
                })
              })
              this.questionList = queslist
            }
          })
    },
    //生成答案列表
    /**
     * 当前编辑答案列表，格式：
     * [
     *  {
     *    options: [
     *      {idx: 0, char: "A", checked: false},
     *      {idx: 1, char: "B", checked: true},
     *      {idx: 2, char: "C", checked: false}
     *    ],
     *    checkStr: "B",
     *    score: 3
     *  }
     * ]
     */
    buildAnswerList (subques) {
      const answerList = []
      let maxscore = 0
      if (subques.answers != null) {
        subques.answers.forEach(ans => {
          const ansOpts = []
          let cstr = ""

          if (subques.type === "1"){
            for (let i = 0; i < subques.areas.length; i++) {
              ansOpts.push({idx: i, char: this.alphabet[i], checked: ans.answer.indexOf(i.toString()) > -1})
              if (ans.answer.indexOf(i.toString()) > -1)
                cstr += this.alphabet[i]
            }
            answerList.push({options: ansOpts, checkStr: cstr, score: ans.score,insufficientScore: ans.insufficientScore})
          }else {
            answerList.push({options: [{idx: ans.answer,char: this.alphabet[ans.answer],checked: true}], checkStr: this.alphabet[ans.answer], score: ans.score,insufficientScore: ans.insufficientScore})
          }


          if (ans.score > maxscore)
            maxscore = ans.score

          subques.maxChoiceCount = ans.maxChoiceCount ? ans.maxChoiceCount : 3
        })

      } else {
        if (subques.type === "1"){
          const ansOpts = []
          for (let i = 0; i < subques.areas.length; i++) {
            ansOpts.push({idx: i, char: this.alphabet[i], checked: false,insufficientScore: 0})
          }
          answerList.push({options: ansOpts, checkStr: "", score: 0,insufficientScore: 0})
        }else {
          for (let i = 0; i < subques.areas.length; i++) {
            answerList.push({options: [{idx: i, char: this.alphabet[i], checked: false}], checkStr: "", score: 0})
          }
        }

        subques.maxChoiceCount = 3

      }
      subques.answerList = answerList
      if (!(subques.ismulti=== true && subques.type == 2)){
        subques.fullScore = maxscore
      }
    },
    //新增得分答案
    toAnswerAdd (subques) {
      const ansOpts = []
      for (let i = 0; i < subques.areas.length; i++) {
        ansOpts.push({idx: i, char: this.alphabet[i], checked: false})
      }
      subques.answerList.push({options: ansOpts, checkStr: "", score: 0})
    },
    //多选题的评分类型改变
    gradeTypeChange(subques){
      // 先清空list
      subques.answerList = []
      subques.fullScore = 0
      // 如果type是2则添加answerList,每个选项都为0分，且只有一个字母
      if (subques.type === "2") {
        for (let i = 0; i < subques.areas.length; i++) {
          subques.answerList.push({options: [{idx: i, char: this.alphabet[i], checked: true}], checkStr: this.alphabet[i], score: 0})
        }

      }else if (subques.type === "1" && subques.ismulti){
        this.toAnswerAdd(subques)
      }
    },
    //删除得分答案
    toAnswerDel (data, subques) {
      if (subques.answerList.length > 1) {
        const anslist = subques.answerList
        const idx = anslist.indexOf(data)
        anslist.splice(idx, 1)
        subques.answerList = anslist
      }
      else
        this.$message.warning("至少要保留一个得分答案")
    },
    //选项标签点击事件
    optionTagClick (data, pare, subques) {
      // 如果是按权累加则不允许他点击
      if (subques.type === "2"){
        return
      }
      data.checked = !data.checked
      let ansStr = ""
      pare.options.forEach(opt => {
        if (!subques.ismulti && opt.idx !== data.idx)
          opt.checked = false
        if (opt.checked)
          ansStr += opt.char
      })
      pare.checkStr = ansStr
    },
    //重新计算子题满分
    reCalcSubquestionMaxScore (subques) {
      let maxscore = 0
      subques.answerList.forEach(ans => {
        if (ans.score > maxscore)
          maxscore = ans.score
      })
      if (!(subques.ismulti=== true && subques.type == 2)){
        subques.fullScore = maxscore
      }
    },
    //保存客观题答案
    toAnswerSave () {
      const subquesAnswer = {
        examid: this.search.examid,
        subjectid: this.search.subjectid
      }
      const subquesList = []
      let errmsg = ""
      this.questionList.forEach(ques => {
        let total = 0
        ques.sub_list.forEach(subques => {
          let iszero = false
          const subans = {
            quesid: subques.quesid,
            subquesid: subques.id,
            type: subques.type,
            fullScore: subques.fullScore
          }
          const answers = []
          let max = 0
          subques.answerList.forEach(ans => {
            let ansstr = ""
            ans.options.forEach(opt => {
              if (opt.checked)
                ansstr += opt.idx.toString()
            })
            if (ansstr !== "") {
              if (ans.score === 0 && subques.type !== "2") { // 按权累加可以允许选项是0分
                iszero = true
                errmsg += "子题：" + subques.name + " 的答案得分不能为0<br>"
              }else if(ans.insufficientScore > ans.score){
                errmsg += "子题：" + subques.name + " 的答案不全的得分不能比完全匹配的得分还高<br>"
              }
              else if (ans.score > max)
                max = ans.score
              answers.push({answer: ansstr, score: ans.score, insufficientScore: ans.insufficientScore,maxChoiceCount: subques.maxChoiceCount})
            }
            else
              iszero = true
          })
          if (!iszero) {
            subans.answers = answers
            subquesList.push(subans)
            total += max
          }
        })
        if (total > ques.maxscore)
          errmsg += ques.name + " 的子题分合计为" + total + "，大于题组总分" + ques.maxscore + "<br>"
      })
      if (subquesList.length === 0)
        errmsg += "至少需要设置一个有效得分答案<br>"
      if (errmsg === "") {
        subquesAnswer.answerlist = subquesList
        this.axios.post("/api/exam/subquestionanswer/saveobjectiveanswer", subquesAnswer)
            .then((response) => {
              if (response.data.success) {
                this.loadSubjectSubquestion()
                this.$message({message: "客观题答案配置保存成功", type: "success"})
              }
            })
      }
      else {
        errmsg = "<div style='max-height: 600px; overflow: auto;'>" + errmsg + "</div>"
        this.$alert(errmsg, "保存出错", {dangerouslyUseHTMLString: true})
      }
    },
    // 导出导入模板
    downloadImportTemplate(){
      const params = {
        examid: this.appStore.exam_id,
        subjectid: this.appStore.subject_id,
      }


      this.axios({
        method: "post",
        url: "/api/exam/subquestionanswer/downloadobjectiveanswerTemplate",
        data: params,
        responseType: 'blob'
      }).then(res => {
        const link = document.createElement('a')
        let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob);
        const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]
        link.setAttribute('download', decodeURIComponent(fileName))
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }, err => {
        console.log(err)
        this.$alert(err, "导出列表出错")
      });
    },
    // 显示上传框
    showUpload() {
      this.uploadVisi = true
      this.$refs["upload"].clearFiles()
    },

    // 关闭上传框
    closeUpload() {
      this.uploadVisi = false
    },

    // 上传前检查
    checkUpload(file) {
      let name = file.name.toLowerCase()
      if (!name.endsWith(".xls") && !name.endsWith(".xlsx")) {
        this.$message({message: "只能上传xls或xlsx文件", type: "warning"})
        return false
      }
      return true
    },

    // 上传后处理
    afterSuccess(data) {
      this.$refs["upload"].clearFiles()
      if (data.success) {
        this.$message({message: "导入成功", type: "success"})
        this.loadSubjectSubquestion()
        this.uploadVisi = false
      } else {
        if (typeof data.result === "string")
          this.$alert(data.result, "导入出错")
        else {
          this.errorData = data.result
          this.errorVisi = true
        }
      }
    },
  }
}
</script>

<style scoped>
.question-card {
  padding: 5px;
  border: solid 1px #EBEEF5;
}

.subquestion-card {
  width: calc(100% - 20px);
  border-bottom: solid 1px #EBEEF5;
  padding: 5px;
  display: flex;
  align-items: center;
}
.subquestion-card:hover {
  box-shadow: #909399 0 1px 3px;
}

.objective-name {
  width: 260px;
  max-width: 260px;
  padding: 0 5px;
}

.objective-answer {
  width: calc(100% - 280px);
  padding: 0 5px;
}

.fixed-buttons {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  padding: 10px 0;
  border-bottom: 1px solid #EBEEF5;
}
</style>