<script>
export default {
  name: "ExamAreaManage",
  props: {
    units: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      examAreas: [],
      loading: false,
      editExamAreaDialog: false,
      editExamAreaData: null,
      addRoomDialog: false,
      addRoomForm: {
        area_id: null,
        room_name_prefix: '',
        room_name_prefix_number: '',
        room_number: 1,
        room_capacity: 30,
        room_name_suffix_number: '',
        room_name_suffix: '',
        room_nick_name_suffix: '',
        room_nick_name_prefix: '',
      },
      currentArea: null,
      editRoomDialog: false,
      editRoomData: null,
      unit: null,
    }
  },
  created() {
  },
  methods: {
    // 获取该单位下所有考区信息
    getExamAreaInfo() {
      this.loading = true
      this.axios.post("/api/examroomdesign/getexamareainfo", {
        unit_id: this.unit.id
      }).then(res => {
        if (res.data.success) {
          this.examAreas = res.data.result.map(area => ({
            ...area,
            rooms: [] // 初始化考场列表
          }))
          // 获取所有考区的考场信息
          const area_ids = this.examAreas.map(area => area.area_id)
          if (area_ids.length > 0) {
            this.getExamRoomList(area_ids)
          }
        }
      }).finally(() => {
        this.loading = false
      })
    },

    // 批量获取考场信息
    getExamRoomList(area_ids) {
      this.axios.post("/api/examroomdesign/getexamroomlist", {
        area_ids: area_ids
      }).then(res => {
        if (res.data.success) {
          // 将返回的考场信息分配到对应的考区
          const roomsByArea = res.data.result
          this.examAreas.forEach(area => {
            area.rooms = roomsByArea.filter(room => room.area_id === area.area_id)
          })
        }
      })
    },

    // 添加考区
    addNewArea() {
      const area = {
        area_id: Date.now(),
        area_name: this.unit.name + "考区" + (this.examAreas.length + 1),
        unit_id: this.unit.id,
        isAdd: true,
      }
      this.saveArea(area)
    },

    // 保存考区
    saveArea(area) {
      if (area.area_name === "") {
        this.$message.error("考区名称不可为空")
        return
      }
      this.axios.post("/api/examroomdesign/saveexamarea", area).then(
        res => {
          if (res.data.success) {
            this.$message.success(area.isAdd ? "新增成功" : "保存成功")
            this.editExamAreaDialog = false
            this.getExamAreaInfo()
            this.$emit('update')
          }
        }
      )
    },

    // 批量添加考场
    batchAddRoom() {
      if (!this.validateRoomForm()) return

      this.axios.post("/api/examroomdesign/batchaddexamroom", this.addRoomForm).then(
        res => {
          if (res.data.success) {
            this.$message.success("批量新增考场成功！")
            this.addRoomDialog = false
            // 获取所有考区的考场信息
            const area_ids = this.examAreas.map(area => area.area_id)
            this.getExamRoomList(area_ids)
          }
        }
      )
    },

    // 验证考场表单
    validateRoomForm() {
      if (this.addRoomForm.room_capacity <= 0) {
        this.$message.error("请输入正确的考场容量")
        return false
      }
      if (!this.addRoomForm.room_name_prefix_number) {
        this.$message.error("请输入考场名称开始数字")
        return false
      }
      if (!this.addRoomForm.room_name_suffix_number) {
        this.$message.error("请输入考场名称结束数字")
        return false
      }
      if (this.addRoomForm.room_name_prefix_number > this.addRoomForm.room_name_suffix_number) {
        this.$message.error("开始数字不能大于结束数字")
        return false
      }
      if (!this.addRoomForm.room_number) {
        this.$message.error("请输入正确的考场号")
        return false
      }
      return true
    },

    // 编辑考区
    editArea(area) {
      this.editExamAreaData = { ...area }
      this.editExamAreaDialog = true
    },

    // 删除考区
    deleteArea(area) {
      this.$confirm("确认删除该考区?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/examroomdesign/deleteexamarea", area).then(
          res => {
            if (res.data.success) {
              this.$message.success("删除成功")
              this.getExamAreaInfo()
              this.$emit('update')
            }
          }
        )
      }).catch(() => {
        this.$message.info("已取消删除")
      })
    },

    // 打开批量添加考场对话框
    openAddRoomDialog(area) {
      this.currentArea = area
      this.addRoomForm = {
        area_id: area.area_id,
        room_name_prefix: area.area_name,
        room_name_prefix_number: '',
        room_capacity: 30,
        room_name_suffix_number: '',
        room_name_suffix: '考场',
        room_nick_name_suffix: '',
        room_nick_name_prefix: '',
      }
      this.addRoomDialog = true
    },

    // 编辑考场
    editRoom(room) {
      this.editRoomData = { ...room }
      this.editRoomDialog = true
    },

    // 删除考场
    deleteRoom(room) {
      this.$confirm("确认删除该考场?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("/api/examroomdesign/deleteexamroom", room).then(
          res => {
            if (res.data.success) {
              this.$message.success("删除成功")
              // 获取所有考区的考场信息
              const area_ids = this.examAreas.map(area => area.area_id)
              this.getExamRoomList(area_ids)
            }
          }
        )
      }).catch(() => {
        this.$message.info("已取消删除")
      })
    },

    // 保存考场信息
    saveRoom() {
      if (!this.editRoomData.room_name) {
        this.$message.error("考场名称不能为空")
        return
      }
      if (!this.editRoomData.room_number) {
        this.$message.error("考场号不能为空")
        return
      }
      if (!this.editRoomData.room_capacity || this.editRoomData.room_capacity <= 0) {
        this.$message.error("请输入正确的考场容量")
        return
      }

      this.axios.post("/api/examroomdesign/saveexamroom", this.editRoomData).then(
        res => {
          if (res.data.success) {
            this.$message.success("保存成功")
            this.editRoomDialog = false
            // 获取所有考区的考场信息
            const area_ids = this.examAreas.map(area => area.area_id)
            this.getExamRoomList(area_ids)
          }
        }
      )
    },
    handleCollapseChange(name) {
      this.unit = this.units.find(unit => unit.id === name)
      this.examAreas = []
      if (name) {
        this.getExamAreaInfo()
      }
    }
  }
}
</script>

<template>
  <div class="area-manage">
    <el-collapse @change="handleCollapseChange" accordion="true">
      <el-collapse-item v-for="unit in units" :key="unit.id" :name="unit.id" :title="unit.name">
        <div style="background: #f5f7fa;padding: 10px;border-radius: 12px;">
          <el-row justify="end" style="margin-bottom: 10px;">
            <el-button type="primary" @click="addNewArea">新增考区</el-button>
          </el-row>
          <el-empty v-if="examAreas.length === 0" description="暂无考区" />
          <el-collapse v-else accordion="true">
            <el-collapse-item v-for="area in examAreas" :key="area.area_id">
              <template #title>
                <div
                  style="display: flex; justify-content: space-between; align-items: center; width: calc(100% - 50px);padding: 0 10px;">
                  <span>{{ area.area_name }}</span>
                  <el-button-group>
                    <el-button type="primary" @click.stop="editArea(area)" size="small">
                      编辑考区
                    </el-button>
                    <el-button type="success" @click.stop="openAddRoomDialog(area)" size="small">
                      添加考场
                    </el-button>
                    <el-button type="danger" @click.stop="deleteArea(area)" size="small">
                      删除考区
                    </el-button>
                  </el-button-group>
                </div>
              </template>
              <el-table :data="area.rooms" border>
                <el-table-column prop="room_name" label="考场名称" align="center" />
                <el-table-column prop="room_nick_name" label="考场别名" align="center" />
                <el-table-column prop="room_number" label="考场号" align="center" />
                <el-table-column prop="room_capacity" label="考场容量" align="center" />
                <el-table-column label="操作" width="200" align="center">
                  <template #default="scope">
                    <el-button-group>
                      <el-button type="primary" size="small" @click="editRoom(scope.row)">
                        编辑
                      </el-button>
                      <el-button type="danger" size="small" @click="deleteRoom(scope.row)">
                        删除
                      </el-button>
                    </el-button-group>
                  </template>
                </el-table-column>
              </el-table>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-collapse-item>
    </el-collapse>

    <!-- 编辑考区弹窗 -->
    <el-dialog v-model="editExamAreaDialog" title="编辑考区" width="30%">
      <el-form :model="editExamAreaData" label-width="100px">
        <el-form-item label="考区名称" required>
          <el-input v-model="editExamAreaData.area_name" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="editExamAreaDialog = false">取消</el-button>
        <el-button type="primary" @click="saveArea(editExamAreaData)">
          确定
        </el-button>
      </template>
    </el-dialog>

    <!-- 批量添加考场弹窗 -->
    <el-dialog v-model="addRoomDialog" title="批量新增考场" width="50%">
      <el-form :model="addRoomForm" label-width="120px" label-position="top">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="考场名称前缀" required>
              <el-input v-model="addRoomForm.room_name_prefix" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考场名称后缀" required>
              <el-input v-model="addRoomForm.room_name_suffix" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-alert type="info" show-icon :closable="false" description="考场名称格式为：考场名称前缀+编号+考场名称后缀"></el-alert>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考场别名前缀">
              <el-input v-model="addRoomForm.room_nick_name_prefix" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考场别名后缀">
              <el-input v-model="addRoomForm.room_nick_name_suffix" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="考场号" required>
              <el-input type="number" v-model="addRoomForm.room_number" :min="1" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-alert type="info" show-icon :closable="false" description="只用输入第一个考场的考场号，后续考场号将根据数量自增"></el-alert>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开始编号" required>
              <el-input-number v-model="addRoomForm.room_name_prefix_number" :min="1" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结束编号" required>
              <el-input-number v-model="addRoomForm.room_name_suffix_number" :min="1" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="考场容量" required>
              <el-input-number v-model="addRoomForm.room_capacity" :min="1" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <el-button @click="addRoomDialog = false">取消</el-button>
        <el-button type="primary" @click="batchAddRoom">确定</el-button>
      </template>
    </el-dialog>

    <!-- 编辑考场弹窗 -->
    <el-dialog v-model="editRoomDialog" title="编辑考场" width="40%">
      <el-form :model="editRoomData" label-width="100px">
        <el-form-item label="考场名称" required>
          <el-input v-model="editRoomData.room_name" />
        </el-form-item>
        <el-form-item label="考场别名">
          <el-input v-model="editRoomData.room_nick_name" />
        </el-form-item>
        <el-form-item label="考场号" required>
          <el-input type="number" v-model="editRoomData.room_number" />
        </el-form-item>
        <el-form-item label="考场容量" required>
          <el-input-number v-model="editRoomData.room_capacity" :min="1" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="editRoomDialog = false">取消</el-button>
        <el-button type="primary" @click="saveRoom">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped>
.area-manage {
  padding: 20px;
}

.manage-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.area-manage :deep(.el-collapse-item__header) {
  font-size: 16px;
  font-weight: bold;
  padding-right: 50px;
}

.area-manage :deep(.el-collapse-item__content) {
  padding: 16px;
}

.area-manage :deep(.el-button-group) {
  margin-left: 16px;
}
</style>